import { Tabs } from "antd";
import React from "react";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import "./MerchantDetails.scss";
import MerchantDetailsTable from "./MerchantDetailsTable";
import AddedByAdminTable from "./AddedByAdminTable";
import { Link } from "react-router-dom";
import { Path } from "../../../Routing/Constant/RoutePaths";

function MerchantConfig() {
  const operations = (
    <Link to={Path.NEWMERCHANT}>
      {" "}
      <ButtonCustom greenBtn label="Add New Merchant" />
    </Link>
  );

  const items = [
    {
      key: "1",
      label: "Added By Admin",
      children: <AddedByAdminTable />,
    },
    {
      key: "2",
      label: "From Website",
      children: <MerchantDetailsTable />,
    },
  ];
  return (
    <div className="merchantDetails">
      <div>
        <Tabs
          defaultActiveKey="1"
          tabBarExtraContent={operations}
          items={items}
        />
      </div>
    </div>
  );
}

export default MerchantConfig;
