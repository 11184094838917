import React, { useState } from "react";
import InputCustom from "../../Ui/input/InputCustom";
import { Col, Flex, Row, message } from "antd";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import ImageUploader from "../../Ui/ImageUploader/ImageUploader";
import CustomBreadcrumb from "../../Ui/CustomBreadcrumb/CustomBreadcrumb";
import { addMerchantSchema } from "../../../Validations/addMerchantSchema";
import { encryption } from "../../Commons/commonFunctions/encryption";
import {
  useAddMerchantFromAdminMutation,
  useImageUploadMutation,
} from "../../../Redux/apiService/merchantApi";
import { useNavigate } from "react-router-dom";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
function AddNewMerchant() {
  const breadcrumbItems = [
    { href: "/merchant", label: "Merchant Details" },
    { label: "Add New Merchant" }, //href: "/newmerchant",
  ];
  const { handleApiError } = useApiErrorHandler();

  const [formValues, setFormValues] = useState({
    merchantName: null,
    emailAddress: null,
    phoneNumber: null,
    platformName: null,
    platformUrl: null,
    logo: null,
    frontIdImage: null,
    backIdImage: null,
  });

  const navigate = useNavigate();
  const [addMerchantFromAdmin] = useAddMerchantFromAdminMutation();
  const [imageUpload] = useImageUploadMutation();
  const [formErrors, setFormErrors] = useState({});

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    try {
      await addMerchantSchema.validateAt(name, { [name]: value });
      setFormErrors({ ...formErrors, [name]: "" });
    } catch (err) {
      setFormErrors({ ...formErrors, [name]: err.message });
    }
  };

  const validateForm = async () => {
    try {
      await addMerchantSchema.validate(formValues, { abortEarly: false });
      setFormErrors({}); // Clear errors if validation passes
      return true; // Validation passed
    } catch (err) {
      const validationErrors = err.inner.reduce((acc, error) => {
        return { ...acc, [error.path]: error.message };
      }, {});
      setFormErrors(validationErrors); // Set validation errors
      return false; // Validation failed
    }
  };

  const handleUploadChange = async (info, fieldName) => {
    const formData = new FormData();
    const file = info.file.originFileObj;

    if (file.size > 1000000) {
      message.error("Image size should be less than 1MB");
    } else {
      formData.append("image", file);
      try {
        const response = await imageUpload(formData).unwrap();
        setFormValues((prevState) => ({
          ...prevState,
          [fieldName]: response.data.image_name, // Dynamically set the image field
        }));
        message.success("Image uploaded successfully");
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const handleSubmit = async () => {
    const isValid = await validateForm(); // Validate the form

    if (!isValid) return; // Stop if validation fails

    try {
      let payloadData = {
        email: formValues.emailAddress,
        platformName: formValues.platformName,
        platformUrl: formValues.platformUrl,
        name: formValues.merchantName,
        logo: formValues.logo,
      };

      if (formValues.phoneNumber) {
        payloadData.phoneNumber = formValues.phoneNumber;
      }
      if (formValues.frontIdImage) {
        payloadData.idFrontImage = formValues.frontIdImage;
      }
      if (formValues.backIdImage) {
        payloadData.idBackImage = formValues.backIdImage;
      }
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const result = await addMerchantFromAdmin(securedData).unwrap();

      if (result?.status === true) {
        message.success(result?.message);
        navigate("/merchant");
      }
    } catch (error) {
      handleApiError(error); // Handle API errors
    }
  };

  const handlePhoneChange = (value) => {
    setFormValues({ ...formValues, phoneNumber: value });
  };

  return (
    <div className="addNewMerchant">
      <CustomBreadcrumb
        items={breadcrumbItems}
        activeIndex={2}
        activeClassName="active-breadcrumb-item"
      />
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12} md={12} lg={12}>
          <InputCustom
            basicInput
            inputCustum
            required
            label="Merchant Name"
            placeholder="Enter Name"
            maxLength={100}
            name="merchantName"
            value={formValues.merchantName}
            onChange={handleChange}
          />
          {formErrors.merchantName && (
            <div className="error-message">{formErrors.merchantName}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <InputCustom
            basicInput
            inputCustum
            required
            label="Email Address"
            placeholder="Enter Email Address"
            maxLength={100}
            name="emailAddress"
            value={formValues.emailAddress}
            onChange={handleChange}
          />
          {formErrors.emailAddress && (
            <div className="error-message">{formErrors.emailAddress}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <label style={{ padding: "10px 0", display: "inline-block" }} for="">
            Phone Number(Optional){" "}
          </label>
          <div className="phone_input">
            <PhoneInput
              // country={'ind'}
              value={formValues.phoneNumber}
              onChange={handlePhoneChange}
              placeholder="Enter Phone Number"
            />
          </div>
          {formErrors.phoneNumber && (
            <div className="error-message">{formErrors.phoneNumber}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <InputCustom
            basicInput
            inputCustum
            required
            label="Platform Name"
            placeholder="Enter Platform Name"
            maxLength={100}
            name="platformName"
            value={formValues.platformName}
            onChange={handleChange}
          />
          {formErrors.platformName && (
            <div className="error-message">{formErrors.platformName}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <InputCustom
            basicInput
            inputCustum
            required
            label="Platform URL"
            placeholder="Enter Platform URL"
            maxLength={100}
            name="platformUrl"
            value={formValues.platformUrl}
            onChange={handleChange}
          />
          {formErrors.platformUrl && (
            <div className="error-message">{formErrors.platformUrl}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <Flex gap={20} align="flex-end" wrap>
            <div>
              <label
                style={{ padding: "10px 0", display: "inline-block" }}
                for=""
              >
                Merchant Logo <span className="required-asterisk"> *</span>
              </label>
              <ImageUploader
                uploadStyle="picture-card"
                handleUploadChange={(info) => handleUploadChange(info, "logo")} // Pass "logo" as the field
                text="Logo"
              />
              {formErrors.logo && (
                <div className="error-message">{formErrors.logo}</div>
              )}
            </div>
            <div>
              <label
                style={{ padding: "10px 0", display: "inline-block" }}
                for=""
              >
                Upload ID <span className="required-asterisk">*</span>
              </label>
              <ImageUploader
                uploadStyle="picture-card"
                handleUploadChange={(info) =>
                  handleUploadChange(info, "frontIdImage")
                }
                text="Front Id"
              />
              {formErrors.frontIdImage && (
                <div className="error-message">{formErrors.frontIdImage}</div>
              )}
            </div>
            <div>
              <ImageUploader
                uploadStyle="picture-card"
                handleUploadChange={(info) =>
                  handleUploadChange(info, "backIdImage")
                }
                text="Back Id"
              />
              {formErrors.backIdImage && (
                <div className="error-message">{formErrors.backIdImage}</div>
              )}
            </div>
          </Flex>
        </Col>
      </Row>
      <div className="addNewMerchant_btn">
        <ButtonCustom greenBtn large label="Add" onClick={handleSubmit} />
      </div>
    </div>
  );
}

export default AddNewMerchant;
