import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../Components/Commons/constants/constant";

export const transactionDetailsApi = createApi({
  reducerPath: "transactionDetailsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.API_URL,
    prepareHeaders: (headers) => {
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("authorization", localStorage.getItem("jwt_token") || "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    trsansactionDetails: builder.mutation({
      query: (data) => ({
        url: "txnHistory/list",
        method: "POST",
        body: data,
      }),
    }),
    refundTransactionAction: builder.mutation({
      query: (data) => ({
        url: "txnHistory/refund",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useTrsansactionDetailsMutation,
  useRefundTransactionActionMutation,
} = transactionDetailsApi;
export default transactionDetailsApi;
