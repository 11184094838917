import * as yup from "yup";

export const addMerchantSchema = yup.object().shape({
  merchantName: yup
    .string()
    .trim() // Trim spaces from start and end
    .required("Merchant Name is required")
    .min(3, "Merchant Name must be at least 3 characters")
    .max(50, "Merchant Name can't be more than 50 characters")
    .max(20, "Merchant Name can't be more than 20 characters")
    .matches(
      /^[A-Za-z\s]+$/,
      "Merchant Name can only contain letters and spaces"
    ), // Only letters and spaces allowed
  emailAddress: yup
    .string()
    .trim() // Trim spaces from start and end
    .required("Email Address is required")
    .email("Please enter a valid email address"),
  phoneNumber: yup 
  .string()
  .nullable()  // The field can be null (i.e., optional)
  .matches(/^\d+$/, "Phone Number can only contain digits")  // Ensures the phone number contains only digits
  .min(6, "Phone Number must be at least 6 digits")  // Minimum of 6 digits
  .max(15, "Phone Number can't be more than 15 digits"), // Optional field
  platformName: yup
    .string()
    .required("Platform Name is required")
    .min(3, "Platform Name must be at least 3 characters")
    .max(50, "Platform Name can't be more than 50 characters"),
  platformUrl: yup
    .string()
    .trim() // Trim spaces from start and end
    .required("Platform URL is required")
    .url("Please enter a valid URL"),
  logo: yup.string().required("Merchant logo is required"),
});
