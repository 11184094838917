import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../Components/Commons/constants/constant";

export const settingApi = createApi({
  reducerPath: "settingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.API_URL,
    prepareHeaders: (headers) => {
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("authorization", localStorage.getItem("jwt_token") || "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    changePassword: builder.mutation({
      query: (data) => ({
        url: "auth/changePassword",
        method: "POST",
        body: data,
      }),
    }),
    maintenanceMode: builder.mutation({
      query: (data) => ({
        url: "auth/maintenanceMode",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useChangePasswordMutation, useMaintenanceModeMutation } = settingApi;
export default settingApi;
