import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import CustomDropdown from "../../Ui/CustomDropdown/CustomDropdown";
import "./TransactionDetails.scss";
import OngoingCompleted from "./OngoingCompleted";
import { useMerchantDropdownListMutation } from "../../../Redux/apiService/currencyPreferencesApi";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { encryption } from "../../Commons/commonFunctions/encryption";
import Refunds from "./Refunds";
function TransactionDetails() {
  const [merchantDropdownList] = useMerchantDropdownListMutation();
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedMerchantName, setSelectedMerchantName] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const { handleApiError } = useApiErrorHandler();

  const fetchMerchantsDropdownList = async () => {
    try {
      const enc = await encryption(JSON.stringify({}));
      const securedData = { dataString: enc };
      const data = await merchantDropdownList(securedData).unwrap();
      if (data?.status === true) {
        const mappedMenuItems = data?.data?.map((merchant) => ({
          key: merchant?.id?.toString(), // Ensure the key is a string
          label: `${merchant.name} (${merchant.email})`, // Name followed by email in brackets
          name: merchant?.name,
        }));
        setMenuItems(mappedMenuItems);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    fetchMerchantsDropdownList();
  }, []);

  const handleResetMerchant = () => {
    setSelectedMerchant(null);
    setSelectedMerchantName(null);
  };

  const onChange = (key) => {};
  const items = [
    {
      key: "1",
      label: "Ongoing/Completed",
      children: (
        <OngoingCompleted
          selectedMerchant={selectedMerchant}
          onResetMerchant={handleResetMerchant}
        />
      ),
    },
    {
      key: "2",
      label: "Refunds",
      children: (
        <Refunds
          selectedMerchant={selectedMerchant}
          onResetMerchant={handleResetMerchant}
        />
      ),
    },
  ];
  return (
    <div className="transactionDetails">
      <div className="transactionDetails_top">
        <CustomDropdown
          menuItems={menuItems}
          buttonText={selectedMerchantName || "Select Merchant"}
          onClick={(selectedItem) => {
            setSelectedMerchant(selectedItem.key);
            setSelectedMerchantName(selectedItem.name);
          }}
        />
      </div>
      <div>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </div>
  );
}

export default TransactionDetails;
