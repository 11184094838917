import { Tabs } from "antd";
import React, { useState } from "react";
import "./Payouts.scss";
import CompletedRequests from "./CompletedRequests";
import PendingRequests from "./PendingRequests";

function Payouts() {
  const [activeKey, setActiveKey] = useState("1");

  const onChange = (key) => {
    setActiveKey(key);
  };

  return (
    <div className="payouts">
      <Tabs activeKey={activeKey} onChange={onChange}>
        <Tabs.TabPane tab="Completed Requests" key="1">
          {activeKey === "1" && <CompletedRequests />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pending Requests" key="2">
          {activeKey === "2" && <PendingRequests />}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default Payouts;
