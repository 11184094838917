import { Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { TableEditIcon, TableEyeIcon } from "../../../Assets/Assets";
import { useLocation, useNavigate } from "react-router-dom";
import { Path } from "../../../Routing/Constant/RoutePaths";
import { useSelector } from "react-redux";
import { encryption } from "../../Commons/commonFunctions/encryption";
import {
  useChangeApiKeyStatusMutation,
  useMerchantListMutation,
} from "../../../Redux/apiService/merchantApi";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";

function AddedByAdminTable() {
  const location = useLocation();
  const currentLocation = location.pathname;
  const searchBarValue = useSelector((state) => state.search.searchValue);

  const navigate = useNavigate();
  const { handleApiError } = useApiErrorHandler();

  const [merchantList] = useMerchantListMutation();
  const [changeApiKeyStatus] = useChangeApiKeyStatusMutation();
  const [merchantListOfAdmin, setMerchantListOfAdmin] = useState([]);
  const [loading, setloading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const fetchMerchantsList = async (resetPage = false) => {
    setloading(true);
    let pageToFetch = resetPage ? 1 : pagination.current;
    let payloadData = {
      page: pageToFetch,
      limit: pagination?.pageSize,
      type: "admin",
    };
    if (currentLocation === Path.MERCHANT && searchBarValue !== "") {
      payloadData.search = searchBarValue;
    }

    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await merchantList(securedData).unwrap();
      if (data?.status === true) {
        setMerchantListOfAdmin(data?.data);
        setPagination({
          ...pagination,
          current: data?.meta?.page,
          total: data?.meta?.total || 0,
        });
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setloading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination?.current,
    });
  };

  useEffect(() => {
    setPagination((prev) => ({ ...prev, current: 1 }));
    fetchMerchantsList(true);
  }, [searchBarValue]);

  useEffect(() => {
    fetchMerchantsList(false);
  }, [pagination?.current]);

  const onSwitchChange = async (checked, merchant) => {
    const newStatus = checked ? 1 : 0;
    let payloadData = {
      id: merchant?.id,
      status: newStatus,
    };
    try {
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await changeApiKeyStatus(securedData).unwrap();
      if (data?.status === true) {
        fetchMerchantsList();
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleEditClick = (merchant) => {
    navigate("editMerchant", { state: { merchant } });
  };

  const handleViewClick = (merchant) => {
    navigate("merchantView", { state: { merchant } });
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Merchant Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Date of Creation",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        if (!created_at) return "N/A"; // Handle case if no date is present

        const date = new Date(created_at);

        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        });

        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        return `${formattedDate} | ${formattedTime}`;
      },
    },
    {
      title: "Platform Name",
      dataIndex: "platform_name",
      key: "platform_name",
    },
    {
      title: "API Key",
      dataIndex: "api_key_status",
      key: "api_key_status",
      render: (api_key_status, merchant) => (
        <Switch
          checked={api_key_status === 1}
          onChange={(checked) => onSwitchChange(checked, merchant)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "transaction",
      key: "transaction",
      render: (_, merchant) => (
        <div className="actionBtn">
          <span onClick={() => handleViewClick(merchant)}>
            <TableEyeIcon />
          </span>
          <span onClick={() => handleEditClick(merchant)}>
            <TableEditIcon />
          </span>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="UserTable">
        <Table
          dataSource={merchantListOfAdmin}
          columns={columns}
          loading={loading}
          tableLayout={"auto"}
          scroll={{ x: "max-content" }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          }}
          onChange={handleTableChange}
        />
      </div>
    </>
  );
}

export default AddedByAdminTable;
