import React, { useEffect, useState } from "react";
import "./CurrencyPreferences.scss";
import { Switch } from "antd";
import { CopyIcon, ValueIcon } from "../../../Assets/Assets";
// import Tether from "../../../Assets/TetherIcon.svg";
import { Col, Row } from "antd";
import CustomDropdown from "../../Ui/CustomDropdown/CustomDropdown";
import {
  useEnableDisableCoinsMutation,
  useGetCoinsListMutation,
  useMerchantDropdownListMutation,
} from "../../../Redux/apiService/currencyPreferencesApi";
import { encryption } from "../../Commons/commonFunctions/encryption";
import {
  coinFamilySymbols,
  exponentialToDecimal,
  formatNumber,
  formatWalletAddress,
  handleCopy,
} from "../../Commons/commonFunctions/commonFunctions";
import { CONFIG } from "../../Commons/constants/constant";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import RadioCustom from "../../Ui/radio/RadioCustom";
import noImage from "./../../../Assets/noImage.png";
function CurrencyPreferences() {
  const [getCoinsList] = useGetCoinsListMutation();
  const [enableDisableCoins] = useEnableDisableCoinsMutation();
  const [merchantDropdownList] = useMerchantDropdownListMutation();
  const [cardData, setCardData] = useState([]);
  const [payoutCardData, setPayoutCardData] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState();
  const [selectedMerchantName, setSelectedMerchantName] = useState();
  const [menuItems, setMenuItems] = useState([]);
  const [selection, setSelection] = useState(1);
  // const [selectedRadio, setSelectedRadio] = useState("");

  const { handleApiError } = useApiErrorHandler();
  const fetchCoinsList = async () => {
    try {
      const enc = await encryption(
        JSON.stringify({
          merchantId: selectedMerchant,
        })
      );
      const securedData = { dataString: enc };
      const data = await getCoinsList(securedData).unwrap();
      if (data?.status === true) {
        setCardData(data?.data);
        setPayoutCardData(data?.data);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleEnableDisableCoins = async (status, card, type) => {
    try {
      let payloadData = {
        coinId: card?.id,
        status,
        merchantId: selectedMerchant,
        coinFamily: card?.coin_family,
        // type,
      };
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await enableDisableCoins(securedData).unwrap();
      if (data?.status === true) {
        fetchCoinsList();
      }
    } catch (error) {
      handleApiError(error);
    }
  };
  const fetchMerchantsDropdownList = async () => {
    try {
      const enc = await encryption(JSON.stringify({}));
      const securedData = { dataString: enc };
      const data = await merchantDropdownList(securedData).unwrap();
      if (data?.status === true) {
        const mappedMenuItems = data?.data?.map((merchant) => ({
          key: merchant?.id?.toString(), // Ensure the key is a string
          label: `${merchant.name} (${merchant.email})`, // Name followed by email in brackets
          name: merchant?.name,
        }));
        setMenuItems(mappedMenuItems);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const onChangeToggle = (checked, card, type) => {
    const status = checked ? 1 : 0;
    handleEnableDisableCoins(status, card, type);
  };
  useEffect(() => {
    if (selectedMerchant) {
      fetchCoinsList();
    }
  }, [selectedMerchant, selection]);
  useEffect(() => {
    fetchMerchantsDropdownList();
  }, []);
  const radioOptions = [
    { value: 1, radioText: "Admin Selection", radioBtn: false },
    { value: 2, radioText: "Merchant Selection", radioBtn: false },
  ];

  return (
    <div className="currencyPreferences">
      <div className="currencyPreferences_head">
        <h2>Deposit Wallet</h2>

        <div className="currencyPreferences_head_right">
          <RadioCustom
            radioText="Admin Selection"
            checked={selection === 1}
            onChange={() => {
              setSelection(1);
              // setSelectedRadio(1);
            }}
          />
          <RadioCustom
            radioText="Merchant Selection"
            checked={selection === 2}
            onChange={() => {
              setSelection(2);
              // setSelectedRadio(2);
            }}
          />
          <CustomDropdown
            menuItems={menuItems}
            buttonText={selectedMerchantName || "Select Merchant"}
            onClick={(selectedItem) => {
              setSelectedMerchant(selectedItem.key);
              setSelectedMerchantName(selectedItem.name);
            }}
          />
        </div>
      </div>
      <div className="depositCard">
        {selection === 1
          ? cardData?.map((card, index) => (
            <div key={index} className="depositCard_item commonCardBG">
              <div className="depositCard_head">
                <h4>Enable/Disable</h4>
                <Switch
                  checked={
                    card?.merchant_coin_data?.admin_coin_status ? true : false
                  }
                  onChange={(checked) =>
                    onChangeToggle(checked, card, "deposit")
                  }
                />
              </div>
              <div className="depositCard_bottom">
                <div className="depositCard_bottom_left">
                  <img
                    src={
                      card.coin_image != null
                        ? `${CONFIG.S3_URL}/${card.coin_image}`
                        : `${noImage}`
                    }
                    alt={"noImage"}
                    height={50}
                    width={50}
                  />
                  <div>
                    <h3>
                      {card?.coin_name} ({(card?.coin_symbol).toUpperCase()}){" "}
                      <small>
                        {card?.is_token === 1 && card?.coin_family ? (
                          <span className="coin-symbol">
                            {coinFamilySymbols[card?.coin_family]}
                          </span>
                        ) : null}
                      </small>
                    </h3>
                    <p>
                      {formatWalletAddress(
                        card?.merchant_coin_data?.wallet_address_data
                          ?.wallet_address
                      )}{" "}
                      <span
                        onClick={() => {
                          handleCopy(
                            card?.merchant_coin_data?.wallet_address_data
                              ?.wallet_address
                          );
                        }}
                      >
                        {card?.merchant_coin_data?.wallet_address_data
                          ?.wallet_address != null ? (
                          <CopyIcon />
                        ) : null}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="depositCard_bottom_right">
                  <h3>
                    {card?.merchant_coin_data?.total_amount ? exponentialToDecimal(formatNumber(
                      card?.merchant_coin_data?.total_amount, card?.decimal_value)) : 0}{" "}
                    {card?.coin_symbol?.toUpperCase()}
                  </h3>
                  <p>
                    <ValueIcon /> $
                    {card?.merchant_coin_data
                      ? (
                        card?.merchant_coin_data?.total_amount *
                        card?.coin_price_in_fiat_data?.current_price
                      )?.toFixed(4)
                      : 0}
                  </p>
                </div>
              </div>
            </div>
          ))
          : cardData?.map((card, index) =>
            card?.merchant_coin_data?.merchant_coin_status === 1 ? (
              <div key={index} className="depositCard_item commonCardBG">
                {/* <div className="depositCard_head">
                    <h4>Enable/Disable</h4>
                    <Switch
                      checked={
                        card?.merchant_coin_data?.merchant_deposit_coin_status
                          ? true
                          : false
                      }
                      onChange={(checked) =>
                        onChangeToggle(checked, card, "deposit")
                      }
                    />
                  </div> */}
                <div className="depositCard_bottom">
                  <div className="depositCard_bottom_left">
                    <img
                      src={
                        card.coin_image != null
                          ? `${CONFIG.S3_URL}/${card.coin_image}`
                          : `${noImage}`
                      }
                      alt={noImage}
                      height={50}
                      width={50}
                    />
                    <div>
                      <h3>
                        {card?.coin_name} ({card?.coin_symbol?.toUpperCase()})
                      </h3>
                      <p>
                        {formatWalletAddress(
                          card?.merchant_coin_data?.wallet_address_data
                            ?.wallet_address
                        )}{" "}
                        <span
                          onClick={() => {
                            handleCopy(
                              card?.merchant_coin_data?.wallet_address_data
                                ?.wallet_address
                            );
                          }}
                        >
                          {card?.merchant_coin_data?.wallet_address_data
                            ?.wallet_address != null ? (
                            <CopyIcon />
                          ) : null}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="depositCard_bottom_right">
                    <h3>
                      {card?.merchant_coin_data?.total_amount ? exponentialToDecimal(
                        formatNumber(card?.merchant_coin_data?.total_amount, card?.decimal_value
                        )) : 0}{" "}
                      {card?.coin_symbol?.toUpperCase()}
                    </h3>
                    <p>
                      <ValueIcon /> $
                      {(
                        card?.merchant_coin_data?.total_amount *
                        card?.coin_price_in_fiat_data?.current_price
                      ).toFixed(4) || " 0"}
                    </p>
                  </div>
                </div>
              </div>
            ) : null
          )}
      </div>
      {selectedMerchant ? (
        <div className="enabledPayout">
          <div className="currencyPreferences_head">
            <h2>Enabled Payout Currencies(Merchant Withdrawal)</h2>
          </div>
          <div className="enabledPayout_card">
            {selection === 1
              ? payoutCardData?.map((card, index) =>
                card?.merchant_coin_data?.admin_coin_status === 1 ? (
                  <div
                    key={index}
                    className="enabledPayout_card_item commonCardBG"
                  >
                    <div className="enabledPayout_card_left">
                      <img
                        src={
                          card.coin_image != null
                            ? `${CONFIG.S3_URL}/${card.coin_image}`
                            : `${noImage}`
                        }
                        alt={"noImage"}
                        height={50}
                        width={50}
                      />
                      <h3>
                        {card?.coin_name} ({card?.coin_symbol?.toUpperCase()}){" "}
                        <small>
                          {card?.is_token === 1 && card?.coin_family ? (
                            <span className="coin-symbol">
                              {coinFamilySymbols[card?.coin_family]}
                            </span>
                          ) : null}
                        </small>
                      </h3>
                    </div>
                    {/* <div>
                      <Switch
                        checked={
                          card?.merchant_coin_data?.admin_withdraw_coin_status
                            ? true
                            : false
                        }
                        onChange={(checked) =>
                          onChangeToggle(checked, card, "withdraw")
                        }
                      />
                    </div> */}
                  </div>
                ) : null
              )
              : payoutCardData?.map((card, index) =>
                card?.merchant_coin_data?.merchant_coin_status === 1 ? (
                  <div
                    key={index}
                    className="enabledPayout_card_item commonCardBG"
                  >
                    <div className="enabledPayout_card_left">
                      <img
                        src={
                          card.coin_image != null
                            ? `${CONFIG.S3_URL}/${card.coin_image}`
                            : `${noImage}`
                        }
                        alt={"noImage"}
                        height={50}
                        width={50}
                      />
                      <h3>
                        {card?.coin_name} ({card?.coin_symbol?.toUpperCase()})
                      </h3>
                    </div>
                    {/* <div>
                        <Switch
                          checked={
                            card?.merchant_coin_data
                              ?.merchant_withdraw_coin_status
                              ? true
                              : false
                          }
                          onChange={(checked) =>
                            onChangeToggle(checked, card, "withdraw")
                          }
                        />
                      </div> */}
                  </div>
                ) : null
              )}
          </div>
        </div>
      ) : (
        <div>Select a merchant to see wallet data</div>
      )}
    </div>
  );
}

export default CurrencyPreferences;
