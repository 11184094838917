import React, { useState } from "react";
import InputCustom from "../../Ui/input/InputCustom";
import { Col, Flex, Row, message } from "antd";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import ImageUploader from "../../Ui/ImageUploader/ImageUploader";
import CustomBreadcrumb from "../../Ui/CustomBreadcrumb/CustomBreadcrumb";
import { addMerchantSchema } from "../../../Validations/addMerchantSchema";
import { encryption } from "../../Commons/commonFunctions/encryption";
import {
  useEditMerchantFromAdminMutation,
  useImageUploadMutation,
} from "../../../Redux/apiService/merchantApi";
import { useLocation, useNavigate } from "react-router-dom";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { CONFIG } from "../../Commons/constants/constant";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
function EditMerchant() {
  const location = useLocation();
  const merchant = location.state?.merchant;
  console.log("EDIT DATA", merchant);
  const navigate = useNavigate();
  const { handleApiError } = useApiErrorHandler();

  const breadcrumbItems = [
    { href: "/merchant", label: "Merchant Details" },
    { label: "Edit Merchant" }, //href: "/newmerchant",
  ];
  const [formValues, setFormValues] = useState({
    merchantName: merchant?.name || null,
    emailAddress: merchant?.email || null,
    phoneNumber: merchant?.phone_number || null,
    platformName: merchant?.platform_name || null,
    platformUrl: merchant?.platform_url || null,
    logo: merchant?.logo || null,
    frontIdImage: merchant?.id_front_image || null,
    backIdImage: merchant?.id_back_image || null,
  });
  const [editMerchantFromAdmin] = useEditMerchantFromAdminMutation();
  const [imageUpload] = useImageUploadMutation();
  const [formErrors, setFormErrors] = useState({});

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    try {
      await addMerchantSchema.validateAt(name, { [name]: value });
      setFormErrors({ ...formErrors, [name]: "" });
    } catch (err) {
      setFormErrors({ ...formErrors, [name]: err.message });
    }
  };

  const handleUploadChange = async (info) => {
    const formData = new FormData();
    const file = info.file.originFileObj;

    if (file.size > 1000000) {
      message.error("Image size should be less than 1MB");
    } else {
      formData.append("image", file);
      try {
        const response = await imageUpload(formData).unwrap();
        setFormValues((prevState) => ({
          ...prevState,
          logo: response.data.image_name,
        }));
        message.success("Image uploaded successfully");
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      await addMerchantSchema.validate(formValues, { abortEarly: false });

      let payloadData = {
        email: formValues.emailAddress,
        platformName: formValues.platformName,
        platformUrl: formValues.platformUrl,
        name: formValues.merchantName,
        id: merchant?.id,
      };
      if (formValues.phoneNumber) {
        payloadData.phoneNumber = formValues.phoneNumber;
      }

      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const result = await editMerchantFromAdmin(securedData).unwrap();

      if (result?.status === true) {
        message.success(result?.message);
        navigate("/merchant");
      }
    } catch (err) {
      const errors = err.inner.reduce((acc, error) => {
        return { ...acc, [error.path]: error.message };
      }, {});
      setFormErrors(errors);
    }
  };
  const handlePhoneChange = (value) => {
    setFormValues({ ...formValues, phoneNumber: value });
  };

  return (
    <div className="addNewMerchant">
      <CustomBreadcrumb
        items={breadcrumbItems}
        activeIndex={2}
        activeClassName="active-breadcrumb-item"
      />
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12} md={12} lg={12}>
          <InputCustom
            basicInput
            inputCustum
            required
            label="Merchant Name"
            placeholder="Enter Name"
            maxLength={100}
            name="merchantName"
            value={formValues.merchantName}
            onChange={handleChange}
          />
          {formErrors.merchantName && (
            <div className="error-message">{formErrors.merchantName}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <InputCustom
            basicInput
            inputCustum
            required
            label="Email Address"
            placeholder="Enter Address"
            maxLength={100}
            name="emailAddress"
            value={formValues.emailAddress}
            onChange={handleChange}
          />
          {formErrors.emailAddress && (
            <div className="error-message">{formErrors.emailAddress}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
        <label style={{padding:'10px 0' ,display:"inline-block"}} for="">Phone Number <span className="required-asterisk"> *</span></label>
          <div className="phone_input">
          <PhoneInput
            // country={'ind'}
            value={formValues.phoneNumber}
            onChange={handlePhoneChange}
            placeholder="Phone Number"
          />
          </div>
          {formErrors.phoneNumber && (
            <div className="error-message">{formErrors.phoneNumber}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <InputCustom
            basicInput
            inputCustum
            required
            label="Platform Name"
            placeholder="Enter Name"
            maxLength={100}
            name="platformName"
            value={formValues.platformName}
            onChange={handleChange}
          />
          {formErrors.platformName && (
            <div className="error-message">{formErrors.platformName}</div>
          )}
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <InputCustom
            basicInput
            inputCustum
            required
            label="Platform URL"
            placeholder="Enter URL"
            maxLength={100}
            name="platformUrl"
            value={formValues.platformUrl}
            onChange={handleChange}
          />
          {formErrors.platformUrl && (
            <div className="error-message">{formErrors.platformUrl}</div>
          )}
        </Col>
        <Col xs={24}  md={12} lg={12}>
        <Flex gap={20} align="flex-end" wrap>
         <div>
         <label style={{padding:'10px 0' ,display:"inline-block"}} for="">Merchant Logo <span className="required-asterisk"> *</span></label>
          <ImageUploader
            uploadStyle="picture-card"
            handleUploadChange={handleUploadChange}
            previewImage={
              formValues.logo ? `${CONFIG.S3_URL}/${formValues.logo}` : null
            }
            text="logo"
            isDisabled={true}
          />
          {formErrors.logo && (
            <div className="error-message">{formErrors.logo}</div>
          )}
       </div>
         <div>
         <label style={{padding:'10px 0' ,display:"inline-block"}}  for="">Upload ID  <span className="required-asterisk">*</span></label>
          <ImageUploader
            uploadStyle="picture-card"
            handleUploadChange={handleUploadChange}
            previewImage={
              formValues.frontIdImage
                ? `${CONFIG.S3_URL}/${formValues.frontIdImage}`
                : null
            }
            text="Front Id"
            isDisabled={true}
          />
          {formErrors.frontIdImage && (
            <div className="error-message">{formErrors.frontIdImage}</div>
          )}
        
        </div>
          <div>
          <ImageUploader
            uploadStyle="picture-card"
            handleUploadChange={handleUploadChange}
            previewImage={
              formValues.backIdImage
                ? `${CONFIG.S3_URL}/${formValues.backIdImage}`
                : null
            }
            text="Back Id"
            isDisabled={true}
          />
          {formErrors.backIdImage && (
            <div className="error-message">{formErrors.backIdImage}</div>
          )}
          </div>
          </Flex>
          </Col>
      </Row>
      <div className="addNewMerchant_btn">
        <ButtonCustom greenBtn large label="Update" onClick={handleSubmit} />
      </div>
    </div>
  );
}

export default EditMerchant;
