import React, { useState } from "react";
import { Dropdown, Menu, Button, Input } from "antd";
import { DownOutlined } from "@ant-design/icons";
import "./CustomDropdown.scss";

function CustomDropdown(props) {
  const { menuItems = [], onClick, buttonText = "Dropdown" } = props;
  const [searchValue, setSearchValue] = useState("");

  // Filter menu items based on search input
  const filteredItems = menuItems.filter((item) =>
    item.label.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleMenuClick = (e) => {
    const selectedItem = menuItems.find((item) => item.key === e.key);
    if (onClick) {
      onClick(selectedItem); // Pass the full item object
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const menu = (
    <div className="merchantDataList">

      <div style={{ padding: "8px" }}>
        <Input
          placeholder="Search..."
          value={searchValue}
          onChange={handleSearch}
        />
      </div>
      <Menu onClick={handleMenuClick}>
        {filteredItems?.map((item, index) => (
          <Menu.Item key={item.key || index}>{item.label}</Menu.Item>
        ))}
      </Menu>
    </div>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} className="customDropdown">
      <Button>
        {buttonText} <DownOutlined />
      </Button>
    </Dropdown>
  );
}

export default CustomDropdown;
