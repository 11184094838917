import React, { useEffect, useState } from "react";
import "./Header.scss";
import { LogoutIcon, SearchIcon } from "../../Assets/Assets";
import { useLocation, useNavigate } from "react-router-dom";
import { Path } from "../../Routing/Constant/RoutePaths.jsx";
import InputCustom from "../Ui/input/InputCustom.jsx";
import { useDispatch } from "react-redux";
import { setSearchValue } from "../../Redux/slices/searchSlice";
import { logout, setTimeZone } from "../../Redux/slices/userSlice";
import { ButtonCustom } from "../Ui/button/ButtonCustom";
import { Modal } from "antd";
function Header() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchInputValue, setSearchInputValue] = useState("");
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchInputValue(value);
    dispatch(setSearchValue(value.trim())); // Use the value directly from the event
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleLogOut = () => {
    dispatch(logout());
    navigate("/login");
  };
  useEffect(() => {
    dispatch(setSearchValue(""));
    setSearchInputValue("");
  }, [location]);

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log("detectedTimezone >>>", detectedTimezone);
    dispatch(setTimeZone(detectedTimezone));
  }, []);

  const currentLocation = location.pathname;
  return (
    <div className="header">
      <h3>
        {currentLocation === Path.DASHBOARD && "Dashboard"}
        {currentLocation === Path.TRANSACTIONS && "Transaction Details"}
        {currentLocation === Path.PREFERENCES && "Currency Preferences"}
        {currentLocation === Path.PAYOUTS && "Payouts"}
        {currentLocation === Path.PAYMENY && "Fee"}
        {currentLocation === Path.MERCHANT && "Merchant Details"}
        {currentLocation === Path.SETTINGS && "Settings"}
        {currentLocation === Path.NEWMERCHANT && "Merchant Details "}
        {currentLocation === Path.MERCHANTVIEW && "Merchant Details"}
      </h3>
      <div className="header_right">
        <InputCustom
          basicInput
          inputSearch
          searchImg={<SearchIcon />}
          inputCustum
          placeholder="Search"
          value={searchInputValue}
          maxLength={100}
          onChange={handleSearch} // Pass handleSearch to InputCustom
          disabled={
            currentLocation === Path.SETTINGS ||
            currentLocation === Path.DASHBOARD ||
            currentLocation === Path.PREFERENCES ||
            currentLocation === Path.NEWMERCHANT ||
            currentLocation === Path.MERCHANTVIEW
          }
        />
        <div
          className="header_right_logOut"
          onClick={() => setIsModalVisible(true)}
          style={{ cursor: "pointer" }}
        >
          <LogoutIcon />
          <h5>Logout</h5>
        </div>
      </div>
      <Modal
        open={isModalVisible}
        onOk={handleModalClose}
        // centered
        onCancel={handleModalClose}
        footer={null}
        maskClosable={false}
        className="common-modal alertModal"
      >
        <div className="modal-content">
          <p>Are you sure you want to logout?</p>
          <div className="alertModal_footer">
            <ButtonCustom
              greenBtn
              large
              label="Yes"
              onClick={() => handleLogOut()}
            />
            <ButtonCustom
              borderBtn
              large
              label="No"
              onClick={handleModalClose}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Header;
