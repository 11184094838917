import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../Components/Commons/constants/constant";

export const payoutsApi = createApi({
  reducerPath: "payoutsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.API_URL,
    prepareHeaders: (headers) => {
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("authorization", localStorage.getItem("jwt_token") || "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    completedPayoutsMerchantList: builder.mutation({
      query: (data) => ({
        url: "payouts/list",
        method: "POST",
        body: data,
      }),
    }),
    completedPayoutsMerchantView: builder.mutation({
      query: (data) => ({
        url: "payouts/view",
        method: "POST",
        body: data,
      }),
    }),
    completedPayoutsEarningCommission: builder.mutation({
      query: (data) => ({
        url: "payouts/earningCommission",
        method: "POST",
        body: data,
      }),
    }),
    pendingPayoutsMerchantList: builder.mutation({
      query: (data) => ({
        url: "payouts/pendingList",
        method: "POST",
        body: data,
      }),
    }),
    updatePendingPayouts: builder.mutation({
      query: (data) => ({
        url: "payouts/updatePayoutStatus",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useCompletedPayoutsMerchantListMutation,
  useCompletedPayoutsMerchantViewMutation,
  usePendingPayoutsMerchantListMutation,
  useUpdatePendingPayoutsMutation,
  useCompletedPayoutsEarningCommissionMutation,
} = payoutsApi;
export default payoutsApi;
