import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../Components/Commons/constants/constant";

export const currencyPreferencesApi = createApi({
  reducerPath: "currencyPreferencesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.API_URL,
    prepareHeaders: (headers) => {
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("authorization", localStorage.getItem("jwt_token") || "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCoinsList: builder.mutation({
      query: (payload) => ({
        url: "currencyPreferences/coinList",
        method: "POST",
        body: payload,
      }),
    }),
    enableDisableCoins: builder.mutation({
      query: (payload) => ({
        url: "currencyPreferences/toggleCoin",
        method: "POST",
        body: payload,
      }),
    }),
    merchantDropdownList: builder.mutation({
      query: (payload) => ({
        url: "merchant/allList",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useEnableDisableCoinsMutation,
  useGetCoinsListMutation,
  useMerchantDropdownListMutation,
} = currencyPreferencesApi;
export default currencyPreferencesApi;
