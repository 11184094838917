import React, { useEffect, useState } from "react";
import { CopyIcon, DownloadIcon, HashIcon } from "../../../Assets/Assets";
import { Table, DatePicker, Dropdown, Button, Space } from "antd";
import CustomDropdown from "../../Ui/CustomDropdown/CustomDropdown";
import RadioCustom from "../../Ui/radio/RadioCustom";
import "./TransactionDetails.scss";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { encryption } from "../../Commons/commonFunctions/encryption";
import { CONFIG } from "../../Commons/constants/constant";
import { useAssetsDropdownListQuery } from "../../../Redux/apiService/merchantApi";
import { DownOutlined } from "@ant-design/icons";
import { useTrsansactionDetailsMutation } from "../../../Redux/apiService/transactionDetailsApi";
import {
  coinFamilySymbols,
  exponentialToDecimal,
  firstUpperCase,
  formatNumber,
  formatWalletAddress,
  handleCopy,
} from "../../Commons/commonFunctions/commonFunctions";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Path } from "../../../Routing/Constant/RoutePaths";
import noImage from "./../../../Assets/noImage.png";
import { CalendarFilled } from "@ant-design/icons";
import dayjs from "dayjs";

function OngoingCompleted({ selectedMerchant, onResetMerchant }) {
  const { RangePicker } = DatePicker;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedTimeline, setSelectedTimeline] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState("");

  const { data: assetsData } = useAssetsDropdownListQuery();
  const [items, setItems] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [trxList, setTrxList] = useState();
  // const [totalPaymentsAmount, setTotalPaymentsAmount] = useState(0);
  const [transactionDetails] = useTrsansactionDetailsMutation();
  const { handleApiError } = useApiErrorHandler();
  const timeZone = useSelector((state) => state?.user?.time_zone);
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const onChange = (date, dateStrings) => {
    if (dateStrings) {
      setStartDate(dateStrings[0] || null);
      setEndDate(dateStrings[1] || null);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const location = useLocation();
  const currentLocation = location.pathname;
  const searchBarValue = useSelector((state) => state.search.searchValue);
  const transactionsList = async (resetPage = false) => {
    setLoading(true);
    let pageToFetch = resetPage ? 1 : pagination.current;
    let payloadData = {
      type: "all",
      page: pageToFetch,
      limit: pagination?.pageSize,
    };
    if (selectedMerchant) {
      payloadData.merchantId = selectedMerchant;
    }
    if (selectedAsset) {
      payloadData.coinId = selectedAsset?.id;
    }
    if (startDate && endDate) {
      payloadData.startDate = startDate;
      payloadData.endDate = endDate;
    }
    // if(startDate){
    //   payloadData.startDate = startDate;
    // }
    // if(endDate){
    //   payloadData.endDate = endDate;
    // }
    if (selectedTimeline && selectedTimeline !== "all") {
      payloadData.timeline = selectedTimeline;
    }
    if (currentLocation === Path.TRANSACTIONS && searchBarValue !== "") {
      payloadData.search = searchBarValue;
    }
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await transactionDetails(securedData).unwrap();
      if (data?.status === true) {
        setTrxList(data?.data);
        setPagination({
          ...pagination,
          current: data?.meta?.page,
          total: data?.meta?.total || 0,
        });
        // setTotalPaymentsAmount(data?.totalFiatAmount);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    setPagination((prev) => ({ ...prev, current: 1 }));
    transactionsList(true);
  }, [searchBarValue]);

  useEffect(() => {
    transactionsList(false);
  }, [
    selectedMerchant,
    selectedAsset,
    startDate,
    endDate,
    selectedTimeline,
    pagination?.current,
  ]);

  const handleAssetSelect = (asset) => {
    setSelectedAsset({
      id: asset.id,
      image:
        asset?.coin_image != null
          ? `${CONFIG.S3_URL}/${asset?.coin_image}`
          : `${noImage}`,
      isToken: asset?.is_token,
      name: asset.coin_name,
    });

    // Reset pagination to page 1
    setPagination((prev) => ({ ...prev, current: 1 }));
  };
  useEffect(() => {
    if (assetsData && assetsData?.status === true) {
      const formattedItems = assetsData?.data?.map((asset) => ({
        label: (
          <p onClick={() => handleAssetSelect(asset)}>
            <img
              src={
                asset.coin_image != null
                  ? `${CONFIG.S3_URL}/${asset.coin_image}`
                  : `${noImage}`
              }
              alt={`${asset.coin_name} icon`}
              height={20}
              width={20}
            />
            {asset?.coin_name}
            <small>
              {asset?.is_token === 1 && asset?.coin_family ? (
                <span className="coin-symbol">
                  ({coinFamilySymbols[asset?.coin_family]})
                </span>
              ) : null}
            </small>
          </p>
        ),
        key: asset.id,
      }));
      setItems(formattedItems);
    }
  }, [assetsData]);

  const menuProps = {
    items,
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Order ID",
      dataIndex: "pay_order_id",
      key: "pay_order_id",
      render: (pay_order_id) => (pay_order_id ? pay_order_id : "N/A"),
    },
    {
      title: "User Email ID",
      dataIndex: "user_email",
      key: "user_email",
      render: (user_email) => <>{user_email != null ? user_email : "N/A"}</>,
    },

    {
      title: "Merchant Email ID",
      dataIndex: "merchant_data",
      key: "merchant_data",
      render: (item) => <>{item?.email}</>,
    },

    {
      title: "User Wallet Address",
      dataIndex: "from_address",
      key: "from_address",
      render: (from_address) => (
        <>
          <span style={{ marginRight: "6px" }}>
            {from_address != null ? formatWalletAddress(from_address) : "N/A"}
          </span>
          {from_address ? (
            <span
              onClick={() => {
                handleCopy(from_address);
              }}
            >
              <CopyIcon />
            </span>
          ) : null}
        </>
      ),
    },
    {
      title: "Date of Transaction",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        if (!created_at) return "N/A"; // Handle case if no date is present

        const date = new Date(created_at);

        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        });

        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        return `${formattedDate} | ${formattedTime}`;
      },
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      render: (_, item) => (
        <span>
          {item?.amount != null ? exponentialToDecimal(formatNumber(item?.amount, item?.coin_data?.decimal_value)) : "N/A"}
        </span>
      ),
      // render: (_, amount) => (
      //   <div>
      //     {`${amount?.amount} ${amount?.coin_data?.coin_symbol?.toUpperCase()}`}{" "}
      //     {amount?.coin_data?.is_token === 1 &&
      //     amount?.coin_data?.coin_family ? (
      //       <span className="coin-symbol">
      //         ({coinFamilySymbols[amount?.coin_data?.coin_family]})
      //       </span>
      //     ) : null}
      //   </div>
      // ),
    },
    {
      title: "Currency",
      dataIndex: "Currency",
      key: "Currency",
      render: (_, item) => (
        <div>
          {`${item?.coin_data?.coin_symbol?.toUpperCase()}`}{" "}
          {item?.coin_data?.is_token === 1 &&
            item?.coin_data?.coin_family ? (
            <span className="coin-symbol">
              ({coinFamilySymbols[item?.coin_data?.coin_family]})
            </span>
          ) : null}
        </div>
      ),
    },
    // {
    //   title: "Amount (USD)",
    //   dataIndex: "amount_in_usd",
    //   key: "amount_in_usd",
    //   render: (amount_in_usd) =>
    //     amount_in_usd != null ? amount_in_usd : "N/A",
    // },
    {
      title: "Transaction Hash",
      dataIndex: "txn_hash",
      key: "txn_hash",
      render: (_, item) => (
        <>
          <span style={{ marginRight: "6px" }}>
            {formatWalletAddress(item?.txn_hash) || "N/A"}
          </span>
          {item?.txn_hash != null ? (
            <span
              onClick={() => {
                onPressView(item);
              }}
            >
              <HashIcon />
            </span>
          ) : null}
        </>
      ),
    },
    {
      title: "Transaction Status",
      dataIndex: "blockchain_status",
      key: "blockchain_status",
      render: (blockchain_status) => {
        let statusColor = "";
        switch (blockchain_status?.toLowerCase()) {
          case "pending":
            statusColor = "#FFD700"; // Yellow
            break;
          case "failed":
            statusColor = "#FF4D4F"; // Red
            break;
          case "confirmed":
            statusColor = "#52C41A"; // Green
            break;
          default:
            statusColor = "gray";
        }

        // Use a span to display the status text with the assigned color
        return (
          <span style={{ color: statusColor }}>
            {blockchain_status === null
              ? "Pending"
              : firstUpperCase(blockchain_status)}
          </span>
        );
      },
    },
  ];

  const onPressView = (item) => {
    const { txn_hash } = item;
    const { coin_family } = item;

    if (coin_family == 2) {
      return window.open("https://etherscan.io/tx/" + txn_hash);
    } else if (coin_family == 3) {
      return window.open("https://bscscan.com/tx/" + txn_hash);
    } else if (coin_family == 1) {
      return window.open("https://live.blockcypher.com/btc/tx/" + txn_hash);
    } else if (coin_family == 4) {
      return window.open("https://polygonscan.com/tx/" + txn_hash);
    } else if (coin_family == 5) {
      return window.open("https://tronscan.org/#/transaction/" + txn_hash);
    }
  };
  const radioOptions = [
    // { value: "all", radioText: "All", radioBtn: true },
    { value: "1m", radioText: "Last Month", radioBtn: false },
    { value: "3m", radioText: "Last 3 Months", radioBtn: false },
    { value: "6m", radioText: "Last 6 Months", radioBtn: false },
    { value: "1y", radioText: "Last 1 Year", radioBtn: false },
  ];

  const onChangeRadio = (e) => {
    setSelectedTimeline(e.target.value);
  };
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination?.current,
    });
  };
  const resetAllFilters = () => {
    setEndDate(null);
    setStartDate(null);
    setSelectedTimeline(null);
    setSelectedRadio("");
    setSelectedAsset(null);
    onResetMerchant(); // Reset the selectedMerchant in TransactionDetails
  };

  const downloadList = () => {
    const baseUrl = `${CONFIG.API_URL}txnHistory/download`;

    const params = new URLSearchParams({
      page: pagination?.current,
      limit: pagination?.pageSize,
      type: "all",
    });

    if (startDate && endDate) {
      params.append("startDate", startDate);
      params.append("endDate", endDate);
    }
    if (timeZone) {
      params.append("timeZone", timeZone);
    }

    if (selectedAsset) {
      params.append("coinId", selectedAsset?.id);
    }

    if (selectedMerchant) {
      params.append("merchantId", selectedMerchant);
    }

    if (selectedTimeline && selectedTimeline !== "all") {
      params.append("timeline", selectedTimeline);
    }
    if (currentLocation === Path.TRANSACTIONS && searchBarValue !== "") {
      params.append("search", searchBarValue);
    }

    const link = `${baseUrl}?${params.toString()}`;
    window.open(link);
  };
  return (
    <div className="ongoingCompleted">
      <div className="ongoingCompleted_filter">
        <div className="ongoingCompleted_filter_btns">
          {selectedTimeline ? null : (
            <RangePicker
              onChange={onChange}
              suffixIcon={<CalendarFilled style={{ color: "black" }} />}
              value={
                startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []
              }
            />
          )}
          <Dropdown menu={menuProps}>
            <Button>
              {selectedAsset ? (
                <img src={selectedAsset.image} alt="" height={20} width={20} />
              ) : (
                ""
              )}
              {selectedAsset?.name || "Filter By Asset"}
              <DownOutlined />
            </Button>
          </Dropdown>
          {!startDate && !endDate ? (
            <>
              <RadioCustom
                radioText="Last Month"
                checked={selectedRadio === "1m"}
                onChange={() => {
                  setSelectedTimeline("1m");
                  setSelectedRadio("1m");
                }}
              />
              <RadioCustom
                radioText="Last 3 Months"
                checked={selectedRadio === "3m"}
                onChange={() => {
                  setSelectedTimeline("3m");
                  setSelectedRadio("3m");
                }}
              />
              <RadioCustom
                radioText="Last 6 Months"
                checked={selectedRadio === "6m"}
                onChange={() => {
                  setSelectedTimeline("6m");
                  setSelectedRadio("6m");
                }}
              />
              <RadioCustom
                radioText="Last 1 Year"
                checked={selectedRadio === "1y"}
                onChange={() => {
                  setSelectedTimeline("1y");
                  setSelectedRadio("1y");
                }}
              />
            </>
          ) : null}
          <Button onClick={resetAllFilters}>Reset All Filters</Button>
        </div>
        <div className="ongoingCompleted_filter_btns">
          {/* <div className="totalPayment">
            <p>
              Total Payments: <span>$ {totalPaymentsAmount}</span>
            </p>
          </div> */}
          <ButtonCustom
            leftIcon={<DownloadIcon />}
            label="Download CSV"
            className="downloadBtn"
            onClick={downloadList}
            disabled={trxList?.length < 1}
          />
        </div>
      </div>
      <div className="UserTable">
        <Table
          dataSource={trxList}
          columns={columns}
          tableLayout={"auto"}
          loading={loading}
          scroll={{ x: "max-content" }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: false, // This will hide the "Select per page" dropdown
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
}

export default OngoingCompleted;
