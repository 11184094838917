import CurrencyPreferences from "../Components/Pages/CurrencyPreferences/CurrencyPreferences";
import Dashboard from "../Components/Pages/Dashboard/Dashboard";
import ForgotPassword from "../Components/Pages/Login/ForgotPassword";
import Login from "../Components/Pages/Login/Login";
import AddNewMerchant from "../Components/Pages/MerchantConfig/AddNewMerchant";
import EditMerchant from "../Components/Pages/MerchantConfig/EditMerchant";
import MerchantConfig from "../Components/Pages/MerchantConfig/MerchantConfig";
import MerchantView from "../Components/Pages/MerchantConfig/MerchantView";
import PaymentDetails from "../Components/Pages/PaymentDetails/PaymentDetails";
import Payouts from "../Components/Pages/Payouts/Payouts";
import ViewDetails from "../Components/Pages/Payouts/ViewDetails/ViewDetails";
import Settings from "../Components/Pages/Settings/Settings";
import TransactionDetails from "../Components/Pages/TransactionDetails/TransactionDetails";
import { Path } from "./Constant/RoutePaths";

export const PAGES = {
  PUBLIC_PAGES: [
    { path: Path.HOME, element: <Login /> },
    { path: Path.LOGIN, element: <Login /> },
    { path: Path.FORGOTPASSWORD, element: <ForgotPassword /> },
  ],

  PRIVATE_PAGES: [
    { path: Path.DASHBOARD, element: <Dashboard /> },
    { path: Path.TRANSACTIONS, element: <TransactionDetails /> },
    { path: Path.PREFERENCES, element: <CurrencyPreferences /> },
    { path: Path.PAYMENY, element: <PaymentDetails /> },
    { path: Path.MERCHANT, element: <MerchantConfig /> },
    { path: Path.SETTINGS, element: <Settings /> },
    { path: Path.PAYOUTS, element: <Payouts /> },
    { path: Path.NEWMERCHANT, element: <AddNewMerchant /> },
    { path: Path.EDITMERCHANT, element: <EditMerchant /> },
    { path: Path.MERCHANTVIEW, element: <MerchantView /> },
    { path: Path.VIEWDETAILS, element: <ViewDetails /> },
  ],
};
