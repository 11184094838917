import React, { useState, useEffect } from "react";
import CustomDropdown from "../../Ui/CustomDropdown/CustomDropdown";
import InputCustom from "../../Ui/input/InputCustom";
import { DatePicker, Table } from "antd";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { DownloadIcon } from "../../../Assets/Assets";
import "./PaymentDetails.scss";
import { useGetFeeDataMutation } from "../../../Redux/apiService/feeApiList";
import { useMerchantDropdownListMutation } from "../../../Redux/apiService/currencyPreferencesApi";
import { useGetMerchentNameDataMutation } from "../../../Redux/apiService/feeApiList";
import { CONFIG } from "../../Commons/constants/constant";
import { useAssetsDropdownListQuery } from "../../../Redux/apiService/merchantApi";
import { Dropdown, Button, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Path } from "../../../Routing/Constant/RoutePaths";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { encryption } from "../../Commons/commonFunctions/encryption";
import { message } from "antd";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import noImage from "./../../../Assets/noImage.png";
import { coinFamilySymbols } from "../../Commons/commonFunctions/commonFunctions";
import { CalendarFilled } from "@ant-design/icons";
import dayjs from "dayjs";

function PaymentDetails() {
  const [GetFeeData] = useGetFeeDataMutation();
  const [getMerchantListData] = useMerchantDropdownListMutation();
  const { data: assetsData } = useAssetsDropdownListQuery();
  const [postMerchantListData] = useGetMerchentNameDataMutation();
  const [data, setData] = useState([]);
  const [merchantList, setMerchantList] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState();
  const [selectedMerchantName, setSelectedMerchantName] = useState();
  const [commissionFee, setCommissionFee] = useState("");
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [items, setItems] = useState([]);
  const [isCommissionUpdated, setIsCommissionUpdated] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const { handleApiError } = useApiErrorHandler();
  const timeZone = useSelector((state) => state?.user?.time_zone);

  const location = useLocation();
  const currentLocation = location.pathname;
  const searchBarValue = useSelector((state) => state.search.searchValue);

  const fetchData = async (resetPage = false) => {
    setLoading(true);
    let pageToFetch = resetPage ? 1 : pagination.current;
    try {
      let payloadData = {
        page: pageToFetch,
        limit: pagination?.pageSize,
      };

      if (selectedAsset) {
        payloadData.coinId = selectedAsset?.id;
      }
      if (currentLocation === Path.PAYMENY && searchBarValue !== "") {
        payloadData.search = searchBarValue;
      }
      if (startDate && endDate) {
        payloadData.startDate = startDate;
        payloadData.endDate = endDate;
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const response = await GetFeeData(securedData).unwrap();
      const feeData = response.data;
      setPagination({
        ...pagination,
        current: response?.meta?.page,
        total: response?.meta?.total || 0,
      });
      setData(feeData);
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination?.current,
    });
  };
  useEffect(() => {
    setPagination((prev) => ({ ...prev, current: 1 }));
    fetchData(true);
  }, [searchBarValue]);

  const fetchMerchantsDropdownList = async () => {
    try {
      const data = await getMerchantListData().unwrap();
      if (data?.status === true) {
        const mappedMenuItems = data?.data?.map((merchant) => ({
          key: merchant?.id?.toString(), // Ensure the key is a string
          label: `${merchant?.name} (${merchant?.email})`, // Name followed by email in brackets
          name: merchant?.name,
        }));
        setMerchantList(mappedMenuItems);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    fetchData(false);
  }, [
    selectedAsset,
    // searchBarValue,
    isCommissionUpdated,
    startDate,
    endDate,
    pagination?.current,
  ]);

  useEffect(() => {
    fetchMerchantsDropdownList();
  }, []);

  const handleAssetSelect = (asset) => {
    setSelectedAsset({
      id: asset.id,
      image:
        asset?.coin_image != null
          ? `${CONFIG.S3_URL}/${asset?.coin_image}`
          : `${noImage}`,
      isToken: asset?.is_token,
      name: asset.coin_name,
    });

    // Reset pagination to page 1
    setPagination((prev) => ({ ...prev, current: 1 }));
  };
  useEffect(() => {
    if (assetsData && assetsData.status === true) {
      const formattedItems = assetsData?.data?.map((asset) => ({
        label: (
          <p onClick={() => handleAssetSelect(asset)}>
            <img
              src={
                asset.coin_image != null
                  ? `${CONFIG.S3_URL}/${asset.coin_image}`
                  : `${noImage}`
              }
              alt={`${asset.coin_name} icon`}
              height={20}
              width={20}
            />{" "}
            {asset?.coin_name}{" "}
            <small>
              {asset?.is_token === 1 && asset?.coin_family ? (
                <span className="coin-symbol">
                  ({coinFamilySymbols[asset?.coin_family]})
                </span>
              ) : null}
            </small>
          </p>
        ),
        key: asset.id,
      }));
      setItems(formattedItems);
    }
  }, [assetsData]);

  const { RangePicker } = DatePicker;

  const onChange = (dates, dateStrings) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  const handleCommissionChange = (e) => {
    const value = e.target.value;
    const validValue = /^(\d{1,2}(\.\d{0,2})?|100(\.00?)?)$/;

    if (validValue.test(value)) {
      setCommissionFee(value);
    } else if (value === "") {
      setCommissionFee(""); // Allow clearing the input
    }
    // else {
    //   message.error(
    //     "Please enter a valid commission between 0 and 100, up to 2 decimal places."
    //   );
    // }
  };

  const handleCommission = async () => {
    try {
      const payload = {
        merchantId: selectedMerchant,
        comissionFee: commissionFee,
      };
      const enc = await encryption(JSON.stringify(payload));
      const securedData = { dataString: enc };
      const response = await postMerchantListData(securedData).unwrap();
      message.success(response?.data);
      setSelectedMerchant();
      setSelectedMerchantName();
      setCommissionFee("");
      setIsCommissionUpdated(true);
    } catch (error) {
      handleApiError(error);
    }
  };

  const menuProps = {
    items,
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Merchant Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Commission Fee (%)",
      dataIndex: "commission_fee_percentage",
      key: "commission_fee_percentage",
      render: (commission_fee_percentage) =>
        commission_fee_percentage != null ? commission_fee_percentage : "N/A",
    },
    {
      title: "Last Updated",
      dataIndex: "date_of_commission_fee_set",
      key: "date_of_commission_fee_set",
      render: (date_of_commission_fee_set) => {
        if (!date_of_commission_fee_set) return "N/A"; // Handle case if no date is present

        const date = new Date(date_of_commission_fee_set);

        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        });

        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        return `${formattedDate} | ${formattedTime}`;
      },
    },

    {
      title: "Currency of Payout",
      dataIndex: "merchant_coin_data",
      key: "merchant_coin_data",
      render: (merchant_coin_data) =>
        Array.isArray(merchant_coin_data) && merchant_coin_data.length > 0
          ? merchant_coin_data
              ?.map((item) => {
                const coinSymbol = item?.coin_data?.coin_symbol?.toUpperCase();
                const coinFamily =
                  item?.coin_data?.is_token === 1
                    ? coinFamilySymbols[item?.coin_data?.coin_family]
                    : null;
                return coinFamily
                  ? `${coinSymbol} (${coinFamily})`
                  : coinSymbol;
              })
              .filter(Boolean) // filter out any undefined or null values
              .join(", ")
          : "N/A",
    },
  ];
  const downloadList = () => {
    const baseUrl = `${CONFIG.API_URL}/fee/download`;

    const params = new URLSearchParams({
      page: pagination?.current,
      limit: pagination?.pageSize,
    });
    if (timeZone) {
      params.append("timeZone", timeZone);
    }
    if (startDate && endDate) {
      params.append("startDate", startDate);
      params.append("endDate", endDate);
    }

    if (selectedAsset) {
      params.append("coinId", selectedAsset?.id);
    }

    if (currentLocation === Path.PAYMENY && searchBarValue !== "") {
      params.append("search", searchBarValue);
    }

    const link = `${baseUrl}?${params.toString()}`;
    console.log("LINKKKK", link);
    window.open(link);
  };

  const resetFilterHandler = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedAsset(null);
  };
  return (
    <div className="paymentDetails">
      <div>
        <div className="paymentDetails_top">
          <div className="paymentDetails_top_filters">
            <CustomDropdown
              menuItems={merchantList}
              onClick={(selectedItem) => {
                setSelectedMerchant(selectedItem.key);
                setSelectedMerchantName(selectedItem.name);
              }}
              buttonText={selectedMerchantName || "Select Merchant"}
            />

            <InputCustom
              basicInput
              inputCustum
              label="Commission Fee (%)"
              value={commissionFee}
              onChange={handleCommissionChange}
              disabled={!selectedMerchant} // Only editable when merchant is selected
            />
            <ButtonCustom
              greenBtn
              label="Save"
              onClick={handleCommission}
              disabled={!selectedMerchant || commissionFee === ""} // Only editable when merchant is selected
            />
          </div>
          <ButtonCustom
            leftIcon={<DownloadIcon />}
            label="Download CSV"
            className="downloadBtn"
            onClick={downloadList}
            disabled={data?.length < 1}
          />
        </div>
        <div className="paymentDetails_asset">
          <RangePicker
            onChange={onChange}
            suffixIcon={<CalendarFilled style={{ color: "black" }} />}
            value={
              startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []
            }
          />
          <Dropdown menu={menuProps}>
            <Button>
              {selectedAsset ? (
                <img
                  src={selectedAsset.image}
                  alt={noImage}
                  height={20}
                  width={20}
                />
              ) : (
                ""
              )}
              {selectedAsset?.name || "Filter By Asset"}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Button onClick={resetFilterHandler}>
            <Space>Reset Filters</Space>
          </Button>
        </div>
      </div>
      <div className="UserTable">
        <Table
          dataSource={data}
          columns={columns}
          loading={loading}
          tableLayout={"auto"}
          scroll={{ x: "max-content" }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: false, // This will hide the "Select per page" dropdown
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
}

export default PaymentDetails;
