import React, { useState } from "react";
import "./Login.scss";
import InputCustom from "../../Ui/input/InputCustom.jsx";
import { ButtonCustom } from "../../Ui/button/ButtonCustom.jsx";
import { Link, useNavigate } from "react-router-dom";
import { Path } from "../../../Routing/Constant/RoutePaths.jsx";
import { useLoginMutation } from "../../../Redux/apiService/loginApi";
import { useDispatch } from "react-redux";
import { setTimeZone, setUser } from "../../../Redux/slices/userSlice";
import { encryption } from "../../Commons/commonFunctions/encryption";
import { loginSchema } from "../../../Validations/loginValidationSchema";
import { message } from "antd";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login] = useLoginMutation();
  const [loading, setLoading] = useState(false); // Add a loading state
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleApiError } = useApiErrorHandler();

  const [errors, setErrors] = useState({});

  const handleChange = async (e) => {
    const { name, value } = e.target;

    // Update state
    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);

    // Validate the specific field
    try {
      await loginSchema.validateAt(name, { [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: err.message }));
      }
    }
  };

  const handleLogin = async () => {
    if (loading) return; // Prevent multiple submissions

    setLoading(true); // Start loading

    try {
      const loginData = {
        email,
        password,
      };

      // Validate the form inputs
      await loginSchema.validate(loginData, { abortEarly: false });

      // If validation passes, proceed with API call
      let enc = await encryption(JSON.stringify(loginData));
      const securedData = { dataString: enc };
      const result = await login(securedData).unwrap();

      if (result?.status === true) {
        dispatch(setUser(result?.data));
        const detectedTimezone =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log("detectedTimezone >>>", detectedTimezone);
        dispatch(setTimeZone(detectedTimezone));
        localStorage.setItem("jwt_token", result?.token);
        navigate(Path.DASHBOARD);
        message.success(result?.message);
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        const formattedErrors = err.inner.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {});
        setErrors(formattedErrors);
      } else {
        handleApiError(err);
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="login" onKeyDown={handleKeyDown}>
      <InputCustom
        basicInput
        inputCustum
        required
        name="email"
        label="Email"
        placeholder="Enter Address"
        maxLength={100}
        value={email}
        onChange={handleChange}
      />
      {errors.email && <p className="error-message">{errors.email}</p>}

      <InputCustom
        passwordInput
        inputCustum
        eyeicon
        required
        name="password"
        label="Password"
        placeholder="Enter Password"
        maxLength={100}
        value={password}
        onChange={handleChange}
      />
      {errors.password && <p className="error-message">{errors.password}</p>}

      <div className="login_link">
        <Link to={Path.FORGOTPASSWORD}>Forgot Password?</Link>
      </div>

      <ButtonCustom
        greenBtn
        large
        label={loading ? "Logging in..." : "Login"}
        customClass="login_btn"
        onClick={handleLogin}
        disabled={loading} // Disable button while loading
      />
    </div>
  );
}

export default Login;
