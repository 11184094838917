export const Path = {
  HOME: "/",
  LOGIN: "/login",
  FORGOTPASSWORD: "/ForgotPassword",
  DASHBOARD: "/dashboard",
  TRANSACTIONS: "/transactions",
  PAYOUTS: "/payouts",
  PREFERENCES: "/Preferences",
  PAYMENY: "/payment",
  MERCHANT: "/merchant",
  SETTINGS: "/settings",
  NEWMERCHANT: "/merchant/newmerchant",
  EDITMERCHANT: "/merchant/editMerchant",
  VIEWDETAILS: "/payouts/viewDetails",
  MERCHANTVIEW: "/merchant/merchantView",
};
