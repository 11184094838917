import React, { useEffect, useState } from "react";
import "./View Details.scss";
import {
  CopyIcon,
  DownloadIcon,
  HashIcon,
  RightArrow,
} from "../../../../Assets/Assets";
import { ButtonCustom } from "../../../Ui/button/ButtonCustom";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Flex, Space, Table } from "antd";
import CustomBreadcrumb from "../../../Ui/CustomBreadcrumb/CustomBreadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import useApiErrorHandler from "../../../Commons/customHooks/useApiErrorHandler";
import {
  useCompletedPayoutsEarningCommissionMutation,
  useCompletedPayoutsMerchantViewMutation,
} from "../../../../Redux/apiService/payoutsApi";
import { encryption } from "../../../Commons/commonFunctions/encryption";
import { useAssetsDropdownListQuery } from "../../../../Redux/apiService/merchantApi";
import { CONFIG } from "../../../Commons/constants/constant";
import { useSelector } from "react-redux";
import { Path } from "../../../../Routing/Constant/RoutePaths";
import noImage from "./../../../../Assets/noImage.png";
import {
  coinFamilySymbols,
  exponentialToDecimal,
  formatNumber,
  formatWalletAddress,
  handleCopy,
} from "../../../Commons/commonFunctions/commonFunctions";

function ViewDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const merchantRecord = location.state;
  // const assetFromBack = location.asset;
  console.log("merchantRecord", merchantRecord);
  const [items, setItems] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const timeZone = useSelector((state) => state?.user?.time_zone);
  const [trxList, setTrxList] = useState();
  const [completedPayoutsMerchantView] =
    useCompletedPayoutsMerchantViewMutation();
  const [completedPayoutsEarningsCommission] =
    useCompletedPayoutsEarningCommissionMutation();
  const { data: assetsData } = useAssetsDropdownListQuery();
  const currentLocation = location.pathname;
  const searchBarValue = useSelector((state) => state.search.searchValue);
  console.log("searchBarValue", searchBarValue);
  const { handleApiError } = useApiErrorHandler();
  const [loading, setLoading] = useState(false); // Loading state
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const breadcrumbItems = [
    { href: "/payouts", label: "Completed Requests" },
    { label: "View Details" },
  ];

  const [totalPaymentsAmount, setTotalPaymentsAmount] = useState();
  useEffect(() => {
    setSelectedAsset(merchantRecord?.asset);
  }, [merchantRecord]);

  const completedPayoutsViewList = async (resetPage = false) => {
    setLoading(true);
    let pageToFetch = resetPage ? 1 : pagination.current;
    let payloadData = {
      merchantId: merchantRecord?.record?.id,
      page: pagination?.current,
      limit: pagination?.pageSize,
    };

    if (selectedAsset) {
      payloadData.coinId = selectedAsset?.id;
    }

    if (currentLocation === Path.VIEWDETAILS && searchBarValue !== "") {
      payloadData.search = searchBarValue;
    }
    try {
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await completedPayoutsMerchantView(securedData).unwrap();
      if (data?.status === true) {
        setTrxList(data?.data);
        setPagination({
          ...pagination,
          current: data?.meta?.page,
          total: data?.meta?.total || 0,
        });
      }
      setLoading(false);
    } catch (error) {
      handleApiError(error);
      setLoading(false);
    }
  };

  const completedPayoutsEarningsAndCommissions = async () => {
    setLoading(true);
    let payloadData = {
      merchantId: merchantRecord?.record?.id,
    };

    if (selectedAsset) {
      payloadData.coinId = selectedAsset?.id;
    }

    if (currentLocation === Path.VIEWDETAILS && searchBarValue !== "") {
      payloadData.search = searchBarValue;
    }
    try {
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await completedPayoutsEarningsCommission(
        securedData
      ).unwrap();
      if (data?.status === true) {
        setTotalPaymentsAmount(data?.data);
      }
      setLoading(false);
    } catch (error) {
      handleApiError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedAsset) {
      setPagination((prev) => ({ ...prev, current: 1 }));
      completedPayoutsViewList(true);
      completedPayoutsEarningsAndCommissions();
    }
  }, [searchBarValue]);

  useEffect(() => {
    if (assetsData && assetsData?.status === true) {
      const formattedItems = assetsData?.data?.map((asset) => ({
        label: (
          <p
            onClick={() =>
              setSelectedAsset({
                id: asset.id,
                image:
                  asset?.coin_image != null
                    ? `${CONFIG.S3_URL}/${asset?.coin_image}`
                    : `${noImage}`,
                isToken: asset?.is_token,
                name: asset.coin_name,
              })
            }
          >
            <img
              src={
                asset.coin_image != null
                  ? `${CONFIG.S3_URL}/${asset.coin_image}`
                  : `${noImage}`
              }
              alt={`${asset.coin_name} icon`}
              height={20}
              width={20}
            />
            {asset?.coin_name}
            <small>
              {asset?.is_token === 1 && asset?.coin_family ? (
                <span className="coin-symbol">
                  ({coinFamilySymbols[asset?.coin_family]})
                </span>
              ) : null}
            </small>
          </p>
        ),
        key: asset.id,
      }));
      setItems(formattedItems);
    }
  }, [assetsData]);

  const menuProps = {
    items,
  };

  useEffect(() => {
    if (!merchantRecord) {
      navigate("/payouts");
    } else if (selectedAsset != null) {
      completedPayoutsViewList(false);
      completedPayoutsEarningsAndCommissions();
    }
  }, [selectedAsset]);

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Date of Request",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        if (!created_at) return "N/A"; // Handle case if no date is present

        const date = new Date(created_at);

        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        });

        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        return `${formattedDate} | ${formattedTime}`;
      },
    },
    {
      title: "Date of Release",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at) => {
        if (!updated_at) return "N/A"; // Handle case if no date is present

        const date = new Date(updated_at);

        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        });

        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        return `${formattedDate} | ${formattedTime}`;
      },
    },
    {
      title: "Merchant Payment",
      dataIndex: "Merchant Payment",
      key: "Merchant Payment",
      render: (_, item) => (
        <span>
          {item?.amount_in_crypto !== null && item?.coin_data !== null && item?.coin_data?.decimal_value !== null ? exponentialToDecimal(formatNumber(item?.amount_in_crypto, item?.coin_data?.decimal_value)) : "N/A"}
        </span>
      ),
    },
    {
      title: "Admin Commission",
      dataIndex: "Admin Commission",
      key: "Admin Commission",
      render: (_, item) => (
        <span>
          {item?.commission_fee_in_crypto != null ? exponentialToDecimal(formatNumber(item?.commission_fee_in_crypto, item?.coin_data?.decimal_value)) : "N/A"}
        </span>
      ),
    },
    {
      title: "Payment Currency",
      dataIndex: "Currency",
      key: "Currency",
      render: (_, item) => (
        <p>
          {item?.coin_data?.coin_symbol?.toUpperCase()}{" "}
          {item?.coin_data?.is_token === 1 && item?.coin_data?.coin_family ? (
            <span className="coin-symbol">
              ({coinFamilySymbols[item?.coin_data?.coin_family]})
            </span>
          ) : null}
        </p>
      ),
    },
    // {
    //   title: "Merchant Payment (USD)",
    //   dataIndex: "amount_in_fiat",
    //   key: "amount_in_fiat",
    // },

    // {
    //   title: "Transaction Status",
    //   dataIndex: "status",to_address
    //   key: "status",
    //   render: (status) =>
    //     status === 3 ? "Confirmed" : status === 1 ? "In Progress" : "N/A",
    // },
    {
      title: "Receiving Address",
      dataIndex: "to_address",
      key: "to_address",
      render: (to_address) => (
        <>
          <span style={{ marginRight: "6px" }}>
            {to_address != null ? formatWalletAddress(to_address) : "N/A"}
          </span>
          {to_address ? (
            <span
              onClick={() => {
                handleCopy(to_address);
              }}
            >
              <CopyIcon />
            </span>
          ) : null}
        </>
      ),
    },
    {
      title: "Transaction Hash",
      dataIndex: "txn_hash",
      key: "txn_hash",
      render: (_, item) => (
        <>
          <span style={{ marginRight: "6px" }}>
            {formatWalletAddress(item?.txn_hash) || "N/A"}
          </span>
          {item?.txn_hash != null ? (
            <span
              onClick={() => {
                onPressView(item);
              }}
            >
              <HashIcon />
            </span>
          ) : null}
        </>
      ),
    },
  ];

  const onPressView = (item) => {
    const { txn_hash } = item;
    const { coin_family } = item.coin_data;
    if (coin_family == 2) {
      return window.open("https://etherscan.io/tx/" + txn_hash);
    } else if (coin_family == 3) {
      return window.open("https://bscscan.com/tx/" + txn_hash);
    } else if (coin_family == 1) {
      return window.open("https://live.blockcypher.com/btc/tx/" + txn_hash);
    } else if (coin_family == 4) {
      return window.open("https://polygonscan.com/tx/" + txn_hash);
    } else if (coin_family == 5) {
      return window.open("https://tronscan.org/#/transaction/" + txn_hash);
    }
  };
  // const resetFilterHandler = () => {
  //   setSelectedAsset(null);
  // };
  const downloadList = () => {
    const baseUrl = `${CONFIG.API_URL}payouts/downloadMerchantRequests`;

    const params = new URLSearchParams({
      page: pagination?.current,
      limit: pagination?.pageSize,
      merchantId: merchantRecord?.record?.id,
    });

    if (selectedAsset) {
      params.append("coinId", selectedAsset?.id);
    }
    if (currentLocation === Path.VIEWDETAILS && searchBarValue !== "") {
      params.append("search", searchBarValue);
    }
    if (timeZone) {
      params.append("timeZone", timeZone);
    }

    const link = `${baseUrl}?${params.toString()}`;
    window.open(link);
  };
  return (
    <div className="viewDetails">
      <div className="viewDetails_top">
        <CustomBreadcrumb
          items={breadcrumbItems}
          activeIndex={2}
          activeClassName="active-breadcrumb-item"
        />
        <Flex gap={10} wrap>
          <div className="totalPayment">
            <p>
              Commission:
              <span>
                $
                {totalPaymentsAmount?.coin_data
                  ? (
                    totalPaymentsAmount?.total_commission_fee_in_crypto *
                    totalPaymentsAmount?.coin_data?.coin_price_in_fiat_data
                      ?.current_price
                  ).toFixed(4)
                  : 0}
              </span>
            </p>
          </div>

          <div className="totalPayment">
            <p>
              Earnings:
              <span>
                $
                {totalPaymentsAmount?.coin_data
                  ? (
                    totalPaymentsAmount?.total_crypto *
                    totalPaymentsAmount?.coin_data?.coin_price_in_fiat_data
                      ?.current_price
                  ).toFixed(4)
                  : 0}
              </span>
            </p>
          </div>
        </Flex>
      </div>
      <div className="viewDetails_cardSec">
        <div className="commonCardBG viewDetails_cardSec_card">
          <div className="viewDetailsText">
            <p className="viewDetailsText_name">Merchant Name</p>
            <p>{merchantRecord?.record?.name}</p>
          </div>
          <div className="viewDetailsText">
            <p className="viewDetailsText_name">Date of Registration</p>
            <p>
              {new Date(
                merchantRecord?.record?.created_at
              ).toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}
            </p>
          </div>
          {/* {merchantRecord?.record?.date_of_approval != null ? (
            <div className="viewDetailsText">
              <p className="viewDetailsText_name">Date of Approval</p>
              <p>
                {new Date(
                  merchantRecord?.record?.date_of_approval
                ).toLocaleDateString()}
              </p>
            </div>
          ) : null} */}
        </div>
        <div className="viewDetails_cardSec_btn">
          {/* <Button onClick={resetFilterHandler}>
            <Space>Reset Filters</Space>
          </Button> */}
          <Dropdown menu={menuProps}>
            <Button>
              {selectedAsset ? (
                <img src={selectedAsset.image} alt="" height={20} width={20} />
              ) : (
                ""
              )}
              {selectedAsset?.name || "Filter By Asset"}
              <DownOutlined />
            </Button>
          </Dropdown>
          <ButtonCustom
            leftIcon={<DownloadIcon />}
            label="Download CSV"
            className="downloadBtn"
            onClick={downloadList}
            disabled={trxList?.length < 1}
          />
        </div>
      </div>
      <div className="UserTable">
        <Table
          loading={loading}
          dataSource={trxList}
          columns={columns}
          tableLayout={"auto"}
          scroll={{ x: "max-content" }}
        />
      </div>
    </div>
  );
}

export default ViewDetails;
