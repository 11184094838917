import React, { useState } from "react";
import "./Settings.scss";
import { Col, Row, message } from "antd";
import { Switch } from "antd";
import InputCustom from "../../Ui/input/InputCustom";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { encryption } from "../../Commons/commonFunctions/encryption";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { logout } from "../../../Redux/slices/userSlice";
import { passwordSchema } from "../../../Validations/changePasswordValidationSchema";
import { useChangePasswordMutation, useMaintenanceModeMutation } from "../../../Redux/apiService/settingsApi";
function Settings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const [MaintenanceMode] = useMaintenanceModeMutation();
  const { handleApiError } = useApiErrorHandler();

  const [allState, setAllState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    error: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const resetState = () => {
    setAllState({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      error: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
    });
  };
  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchChange = async (checked) => {
    setIsChecked(checked);
    const ModeNO = checked ? 1 : 0; 
  
    try {
      // Encrypt the status
      const enc = await encryption(
        JSON.stringify({
          status: ModeNO,
        })
      );
      const status = { dataString: enc };
      console.log("Encrypted Data:", status);
      const response = await MaintenanceMode(status).unwrap();
      console.log("response", response);
    } catch (error) {
      handleApiError(error)
    }
  };


  const handleValidation = async (values) => {
    try {
      await passwordSchema.validate(values, { abortEarly: false });
      return true;
    } catch (validationErrors) {
      const errors = validationErrors.inner.reduce((acc, err) => {
        return { ...acc, [err.path]: err.message };
      }, {});
      setAllState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          ...errors,
        },
      }));
      return false;
    }
  };
  const handleChange = async (e) => {
    const { name, value } = e.target;

    // Clear the error for the specific field being updated
    setAllState((prevState) => ({
      ...prevState,
      [name]: value,
      error: {
        ...prevState.error,
        [name]: "",
      },
    }));

    const validationData = {
      ...allState,
      [name]: value,
    };

    // Validate the updated value
    await handleValidation(validationData);
  };

  const handleChangePassword = async () => {
    const isValid = await handleValidation(allState);

    if (isValid) {
      try {
        const enc = await encryption(
          JSON.stringify({
            oldPassword: allState.oldPassword,
            newPassword: allState.newPassword,
          })
        );
        const securedData = { dataString: enc };
        const result = await changePassword(securedData).unwrap();
        message.success(result?.message);
        setTimeout(() => {
          dispatch(logout());
          navigate("/login");
        }, 0);
      } catch (error) {
        console.log("ERROR", error);
        message.error(error?.data?.message || "Failed to change password");
        if (error?.data?.message === "Invalid Token, please try again.") {
          navigate("/login");
        }
      } finally {
        resetState();
      }
    } else {
      console.log("Validation failed:", allState.error);
    }
  };

  return (
    <div className="setting">
      <div className="setting_head">
        <h2>Change Password</h2>
        <h2>
          Enable Maintenance Mode <Switch
            checked={isChecked}
            onChange={handleSwitchChange}
          />
        </h2>
      </div>
      <Row gutter={[20, 20]} className="setting_inputs">
        <Col lg={8}>
          <InputCustom
            basicInput
            inputCustum
            required
            label="Old Password"
            placeholder="Enter Old Password"
            maxLength={100}
            name="oldPassword" // Name prop added
            value={allState.oldPassword}
            onChange={handleChange} // handleChange function called here
            error={allState.error.oldPassword}
          />
          {allState.error.oldPassword && (
            <div className="error-message">{allState.error.oldPassword}</div>
          )}
        </Col>
        <Col lg={8}>
          <InputCustom
            basicInput
            inputCustum
            required
            label="New Password"
            placeholder="Enter New Password"
            maxLength={100}
            name="newPassword" // Name prop added
            value={allState.newPassword}
            onChange={handleChange} // handleChange function called here
            error={allState.error.newPassword}
          />
          {allState.error.newPassword && (
            <div className="error-message">{allState.error.newPassword}</div>
          )}
        </Col>
        <Col lg={8}>
          <InputCustom
            basicInput
            inputCustum
            required
            label="Confirm Password"
            placeholder="Confirm New Password"
            maxLength={100}
            name="confirmPassword" // Name prop added
            value={allState.confirmPassword}
            onChange={handleChange} // handleChange function called here
            error={allState.error.confirmPassword}
          />
          {allState.error.confirmPassword && (
            <div className="error-message">
              {allState.error.confirmPassword}
            </div>
          )}
        </Col>
      </Row>
      <ButtonCustom
        greenBtn
        large
        label="Change Password"
        customClass="setting_Btn"
        onClick={handleChangePassword}
        disabled={isLoading}
      />
    </div>
  );
}

export default Settings;
