// import NodeRSA from 'node-rsa';
import JSEncrypt from "jsencrypt";
const RSAkey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAjp17YNrdAl+JqDpBB7Ju
h8WnDMmQ7aTlv0bXz9h1hAm8Mt7JmQT37wsk3k+/gYML2xujvyq/YnwsrVi1G879
SfCiMkS6XvduKzuU3zlhzceoiEQFWqAJdS77/c/q5jzR4ROQrmkqbPH8cydNCMCk
EzSKpNVY5sV3dguo/shFGWIhMChRBz0oUfs0OxAQOl9PillkUoyH4q2VNAQmx3Sp
NsJ61rAC+nK4RUiIOjGBZCF6vLXvrHQGwGzjY7UBHWv+1BGJtW4ap8JBq8/Vz5UI
suiYmHboxpPevp7BZTWzSj1QRvSF1AEZQm9leVkgAJYQCXYgqW2mpk+qddbkfFCo
pWMHn7KVery4VpGO15xtzT/T5l/WvkU0IqJd5i/+mdcoFj9IkR2Q9OA/DLlj7w0K
U2/iOph0eP+HTaDUfx1lU4a8341hVnWvISKkgAz1uEbJQXAitfXHIpWk7BGprHD0
TPl0ws+qhDQTo0/GtwJFd1gqB7gcEL3Ep7q462JHzi6yIuIM6GzSQI0jQd2Zqp/G
UWK39xG6VRHeNRWpG/lBYLBa5jNcF+WmCtoMZH/NJK2lxN+LTXns09PbSRJLJjwq
6TPdVFnM1mU7TKOMke7YMdiFZT8l1VCuyYlcy+w1eT5dzlDsvsdSzIMi4wwE5UIm
/4UNet6Kwt0pqh2eDX6bODcCAwEAAQ==
-----END PUBLIC KEY-----`;

// Assuming you're using JSEncrypt for encryption

export const encryption = async (data) => {
  console.log("DATA IN PAYLOAD BEFORE ENCRYPTION ::", data);
  try {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(RSAkey);
    const encStr = encrypt.encrypt(data);
    return encStr;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
