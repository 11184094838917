import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Dropdown,
  Space,
  Switch,
  Table,
  message,
} from "antd";

import CustomDropdown from "../../Ui/CustomDropdown/CustomDropdown";
import "./Payouts.scss";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { TableEyeIcon } from "../../../Assets/Assets";
import { useLocation, useNavigate } from "react-router-dom";
import { useAssetsDropdownListQuery } from "../../../Redux/apiService/merchantApi";
import {
  usePendingPayoutsMerchantListMutation,
  useUpdatePendingPayoutsMutation,
} from "../../../Redux/apiService/payoutsApi";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { useSelector } from "react-redux";
import { Path } from "../../../Routing/Constant/RoutePaths";
import { encryption } from "../../Commons/commonFunctions/encryption";
import { CONFIG } from "../../Commons/constants/constant";
import { DownOutlined } from "@ant-design/icons";
import noImage from "./../../../Assets/noImage.png";
import { coinFamilySymbols, exponentialToDecimal, formatNumber } from "../../Commons/commonFunctions/commonFunctions";
import { CalendarFilled } from "@ant-design/icons";

function PendingRequests() {
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const [trxList, setTrxList] = useState();
  const [pendingPayoutsMerchantList] = usePendingPayoutsMerchantListMutation();
  const [updatePendingPayouts, { isloading }] =
    useUpdatePendingPayoutsMutation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [payoutItems, setPayoutItems] = useState();
  const [payoutStatus, setPayoutStatus] = useState();
  const [title, setTitle] = useState("");

  const { data: assetsData } = useAssetsDropdownListQuery();
  const [items, setItems] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [loading, setloading] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const location = useLocation();
  const currentLocation = location.pathname;
  const searchBarValue = useSelector((state) => state.search.searchValue);

  const { handleApiError } = useApiErrorHandler();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  useEffect(() => {
    if (selectedAsset && selectedAsset?.id !== undefined) {
      setPagination((prev) => ({ ...prev, current: 1 }));
      pendingPayoutsList(true);
    }
  }, [searchBarValue, selectedAsset]);

  useEffect(() => {
    if (selectedAsset && selectedAsset?.id !== undefined) {
      pendingPayoutsList(false);
    }
  }, [pagination.current, startDate, endDate]);

  const onChange = (dates, dateStrings) => {
    // console.log("Formatted Selected Dates: ", dateStrings);
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRowsData(selectedRows);
      setSelectedRowKeys(selectedRowKeys); // Update selected row keys
    },
    getCheckboxProps: (record) => ({
      disabled: record?.status === 2 || record?.status === 1, // Disable if the status is "Rejected"
    }),
  };

  useEffect(() => {
    setSelectedRowsData([]);
    setSelectedRowKeys([]); // Clear the selected row keys in the table
  }, [selectedAsset, pagination?.current]);

  const pendingPayoutsList = async (resetPage = false) => {
    setloading(true);
    let pageToFetch = resetPage ? 1 : pagination.current;

    let payloadData = {
      page: pageToFetch,
      limit: pagination?.pageSize,
      coinId: selectedAsset?.id,
    };
    if (selectedAsset) {
      payloadData.coinId = selectedAsset?.id;
    }
    if (startDate && endDate) {
      payloadData.startDate = startDate;
      payloadData.endDate = endDate;
    }
    if (currentLocation === Path.PAYOUTS && searchBarValue !== "") {
      payloadData.search = searchBarValue;
    }
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await pendingPayoutsMerchantList(securedData).unwrap();
      if (data?.status === true) {
        setTrxList(data?.data);
        setPagination({
          ...pagination,
          current: data?.meta?.page,
          total: data?.meta?.total || 0,
        });
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setloading(false);
    }
  };

  const pendingPayoutsAcceptReject = async (selectedItems, status) => {
    let payloadData;

    if (!Array.isArray(selectedItems)) {
      // Single item case
      payloadData = {
        updatePayoutStatusList: [
          {
            merchantId: selectedItems?.merchant_data?.id,
            id: selectedItems?.id,
            status,
          },
        ],
        coinId: selectedItems?.coin_data?.id,
      };
    } else {
      // Multiple items case
      const firstItem = selectedItems[0];
      const coinId = firstItem?.coin_data?.id;
      payloadData = {
        updatePayoutStatusList: selectedItems?.map((item) => ({
          merchantId: item?.merchant_data?.id,
          id: item?.id,
          status,
        })),
        coinId: coinId,
      };
    }

    try {
      setloading(true);
      await new Promise((resolve) => setTimeout(resolve, 500));
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await updatePendingPayouts(securedData).unwrap();
      if (data?.status === true) {
        message.success(data?.message);
        pendingPayoutsList();
        setSelectedRowsData([]);
        setSelectedRowKeys([]);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setloading(false);
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination?.current,
    });
  };

  const menuProps = {
    items,
  };

  const handleAssetSelect = (asset) => {
    setSelectedAsset({
      id: asset.id,
      image:
        asset?.coin_image != null
          ? `${CONFIG.S3_URL}/${asset?.coin_image}`
          : `${noImage}`,
      isToken: asset?.is_token,
      name: asset.coin_name,
    });

    setPagination((prev) => ({ ...prev, current: 1 }));
  };
  useEffect(() => {
    if (assetsData && assetsData?.status === true) {
      let formattedItems = [];
      let i = 0;
      for (const asset of assetsData?.data) {
        //const formattedItems = assetsData?.data?.map((asset) => {
        if (i == 0) {
          setSelectedAsset({
            id: asset.id,
            image:
              asset?.coin_image != null
                ? `${CONFIG.S3_URL}/${asset?.coin_image}`
                : `${noImage}`,
            isToken: asset?.is_token,
            name: asset.coin_name,
          });
        }

        let data = {
          label: (
            <p onClick={() => handleAssetSelect(asset)}>
              <img
                src={
                  asset.coin_image != null
                    ? `${CONFIG.S3_URL}/${asset.coin_image}`
                    : `${noImage}`
                }
                alt={`${asset.coin_name} icon`}
                height={20}
                width={20}
              />{" "}
              {asset?.coin_name}{" "}
              <small>
                {asset?.is_token === 1 && asset?.coin_family ? (
                  <span className="coin-symbol">
                    ({coinFamilySymbols[asset?.coin_family]})
                  </span>
                ) : null}
              </small>
            </p>
          ),
          key: asset.id,
        };
        formattedItems.push(data);
        i++;
      }
      //});
      setItems(formattedItems);
    }
  }, [assetsData]);

  const columns = [
    {
      title: "Merchant Name",
      dataIndex: "merchant_data",
      key: "merchant_data",
      render: (merchant_data) =>
        // console.log("DATA",data)
        merchant_data?.name,
    },
    {
      title: "Merchant Email ID",
      dataIndex: "merchant_data",
      key: "merchant_data",
      render: (merchant_data) =>
        // console.log("DATA",data)
        merchant_data?.email,
    },
    {
      title: "Requested Withdrawal Amount",
      dataIndex: "Requested Withdrawal Amount",
      key: "Requested Withdrawal Amount",
      render: (_, item) => (
        <span>
          {item?.request_amount_in_crypto != null ? exponentialToDecimal(formatNumber(item?.request_amount_in_crypto, item?.coin_data?.decimal_value)) : "N/A"}
        </span>
      ),
    },
    {
      title: "Payment Currency",
      dataIndex: "coin_data",
      key: "coin_data",
      render: (coin_data) => (
        <div>
          <span>
            {coin_data?.coin_symbol?.toUpperCase()}{" "}
            {coin_data?.is_token === 1 && coin_data?.coin_family ? (
              <span className="coin-symbol">
                ({coinFamilySymbols[coin_data?.coin_family]})
              </span>
            ) : null}
          </span>
        </div>
      ),
    },
    {
      title: "Date of Request",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        if (!created_at) return "N/A"; // Handle case if no date is present

        const date = new Date(created_at);

        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        });

        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        return `${formattedDate} | ${formattedTime}`;
      },
    },

    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (_, item) =>
        item?.status === 0 || item?.status === 4 ? (
          <div className="status">
            <div className="accept">
              <span
                onClick={() => pendingPayoutsAcceptReject(item, 1)}
                style={{ cursor: "pointer" }}
              >
                Accept
              </span>
            </div>
            <div className="reject">
              <span
                onClick={() => pendingPayoutsAcceptReject(item, 2)}
                style={{ cursor: "pointer" }}
              >
                Reject
              </span>
            </div>
          </div>
        ) : item?.status === 2 ? (
          <div className="status">
            {/* <div className="reject"> */}
            <span>Rejected</span>
            {/* </div> */}
          </div>
        ) : item?.status === 1 ? (
          <div className="status">
            {/* <div className="reject"> */}
            <span>In progress</span>
            {/* </div> */}
          </div>
        ) : null,
    },
  ];

  const handleAcceptAll = () => {
    pendingPayoutsAcceptReject(selectedRowsData, 1); // Accept status is 1
  };

  const handleRejectAll = () => {
    pendingPayoutsAcceptReject(selectedRowsData, 2); // Reject status is 2
  };

  return (
    <div className="completedRequests">
      <div className="completedRequests_top">
        <div className="completedRequests_top">
          <RangePicker
            onChange={onChange}
            suffixIcon={<CalendarFilled style={{ color: "black" }} />}
          />
          <Dropdown menu={menuProps}>
            <Button>
              {selectedAsset ? (
                <img src={selectedAsset.image} alt="" height={20} width={20} />
              ) : (
                ""
              )}
              {selectedAsset?.name || "Filter By Asset"}

              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <div className="PendingRequestsBtn">
          <ButtonCustom
            onClick={handleAcceptAll}
            className={selectedRowsData.length === 0 ? "disable" : "approveBtn"}
            label="Accept All"
            disabled={selectedRowsData.length === 0}
          ></ButtonCustom>
          <ButtonCustom
            onClick={handleRejectAll}
            className={selectedRowsData.length === 0 ? "disable" : "rejectBtn"}
            label="Reject All"
            disabled={selectedRowsData.length === 0}
          ></ButtonCustom>
          {/* <ButtonCustom label="Reject All" className="rejectBtn" /> */}
        </div>
      </div>
      <div className="UserTable">
        <Table
          rowSelection={rowSelection}
          dataSource={trxList}
          loading={loading}
          rowKey={(record) => record?.id}
          columns={columns}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: false, // This will hide the "Select per page" dropdown
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
}

export default PendingRequests;
