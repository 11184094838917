import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../Components/Commons/constants/constant";

export const feeApiListApi = createApi({
  reducerPath: "feeApiListApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.API_URL,
    prepareHeaders: (headers) => {
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("authorization", localStorage.getItem("jwt_token") || "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getFeeData: builder.mutation({
      query: (payload) => ({
        url: "fee/list",
        method: "POST",
        body: payload,
      }),
    }),
    getMerchentNameData: builder.mutation({
      query: (payload) => ({
        url: "fee/setCommission",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useGetFeeDataMutation, useGetMerchentNameDataMutation } =
  feeApiListApi;
export default feeApiListApi;
