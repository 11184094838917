import React, { useEffect, useState } from "react";
import "./MerchantDetails.scss";
import {
  CopyIcon,
  DollerSwapIcon,
  EarnedPayoutsIcon,
} from "../../../Assets/Assets";
import { Col, Row, Modal, Dropdown, Button, Space, Flex } from "antd";
import CustomBreadcrumb from "../../Ui/CustomBreadcrumb/CustomBreadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAssetsDropdownListQuery,
  useEarnedPayoutsMutation,
  useMerchantViewDetailsMutation,
  useUserPaymentsVolumeMutation,
} from "../../../Redux/apiService/merchantApi";
import { encryption } from "../../Commons/commonFunctions/encryption";
import { CONFIG } from "../../Commons/constants/constant";
import { DownOutlined } from "@ant-design/icons";
import {
  calculateTimeAgo,
  coinFamilySymbols,
  exponentialToDecimal,
  formatNumber,
  formatWalletAddress,
  handleCopy,
} from "../../Commons/commonFunctions/commonFunctions";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import noImage from "./../../../Assets/noImage.png";

function MerchantView() {
  const { handleApiError } = useApiErrorHandler();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);

  const [merchantDetails, setMerchantDetails] = useState();
  const [earnedPayoutForMerchant, setEarnedPayoutForMerchant] = useState();
  const [userPaymentsVolumeForMerchant, setUserPaymentsVolumeForMerchant] =
    useState();
  const [filter, setFilter] = useState("1D");
  const location = useLocation();
  const merchant = location.state?.merchant;
  const [timeAgo, setTimeAgo] = useState(""); // State to store the formatted time ago

  const [merchantViewDetails] = useMerchantViewDetailsMutation();
  const [earnedPayouts] = useEarnedPayoutsMutation();
  const [userPaymentsVolume] = useUserPaymentsVolumeMutation();
  const { data: assetsData, refetch } = useAssetsDropdownListQuery();

  const [items, setItems] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState({
    id: 1,
    image: `${CONFIG.S3_URL}/triskel-pay/btc.png`,
    decimals: 8,
    coinFamily: 1,
    isToken: 0
  });
  const [selectedEarnedAsset, setSelectedEarnedAsset] = useState({
    id: 1,
    image: `${CONFIG.S3_URL}/triskel-pay/btc.png`,
    decimals: 8,
    coinFamily: 1,
    isToken: 0
  });

  const fetchMerchantsList = async () => {
    let payloadData = {
      merchantId: merchant?.id,
    };
    try {
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await merchantViewDetails(securedData).unwrap();
      // setMerchantDetails(data?.data);

      if (data?.status === true) {
        setMerchantDetails(data?.data);
        // if(data?.data?.merchant_coin_data[0]?.merchant_coin_status===1)
        const updatedTime =
          data?.data?.merchant_coin_data[0]?.wallet_address_data?.updated_at;
        if (updatedTime) {
          setTimeAgo(calculateTimeAgo(updatedTime)); // Calculate time ago for display
        } else {
          setTimeAgo("");
        }
      }
    } catch (error) {
      handleApiError(error);
    }
  };
  const earnedPayout = async () => {
    let payloadData = {
      merchantId: merchant?.id,
      coinId: selectedEarnedAsset?.id,
    };
    try {
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await earnedPayouts(securedData).unwrap();
      if (data?.status === true) {
        setEarnedPayoutForMerchant(data?.data);
      }
    } catch (error) {
      handleApiError(error);
    }
  };
  const userPaymentVolume = async () => {
    let payloadData = {
      merchantId: merchant?.id,
      timeline: filter,
      coinId: selectedAsset.id,
    };
    try {
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await userPaymentsVolume(securedData).unwrap();
      if (data?.status === true) {
        setUserPaymentsVolumeForMerchant(data?.data);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };
  useEffect(() => {
    if (merchant?.id) {
      fetchMerchantsList();
      earnedPayout();
    } else {
      navigate("/merchant");
    }
  }, [selectedEarnedAsset]);

  useEffect(() => {
    if (merchant?.id) {
      userPaymentVolume();
    } else {
      navigate("/merchant");
    }
  }, [filter, selectedAsset]);

  const handleOpenClick = (type) => {
    if (type === 1) {
      setIsModalVisible2(true);
    } else if (type === 2) {
      setIsModalVisible3(true);
    } else {
      setIsModalVisible(true);
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const handleModalClose2 = () => {
    setIsModalVisible2(false);
  };
  const handleModalClose3 = () => {
    setIsModalVisible3(false);
  };

  const breadcrumbItems = [
    { href: "/merchant", label: "Merchant Details" },
    { label: "View Details" }, // href: "/merchantView"
  ];

  useEffect(() => {
    if (assetsData && assetsData.status === true) {
      console.log('assetsData>>>', assetsData);
      // Format items to include both displayLabel (for UI) and label (for selection)
      const formattedItems = assetsData?.data?.map((asset) => ({
        id: asset.id,
        label: asset?.coin_name, // Use a plain label for dropdown selection
        value: asset?.coin_name, // Use a plain value for dropdown selection
        image: asset?.coin_image
          ? `${CONFIG.S3_URL}/${asset.coin_image}`
          : `${noImage}`,
        isToken: asset?.is_token,
        coinFamily: asset?.coin_family,
        decimals: asset?.decimal_value,
        displayLabel: (
          <p>
            <img
              src={
                asset.coin_image != null
                  ? `${CONFIG.S3_URL}/${asset.coin_image}`
                  : `${noImage}`
              }
              alt={`${asset.coin_name} icon`}
              height={20}
              width={20}
            />
            {asset?.coin_name}
            {asset?.is_token === 1 && asset?.coin_family ? (
              <small className="coin-symbol">
                ({coinFamilySymbols[asset?.coin_family]})
              </small>
            ) : null}
          </p>
        ),
      }));

      setItems(formattedItems);
    }
  }, [assetsData]);

  useEffect(() => {
    refetch();
  }, []);

  const handleSelectAsset = (asset, type) => {
    const newAsset = {
      id: asset?.id,
      image: asset?.image,
      isToken: asset?.isToken,
      coinFamily: asset?.coinFamily,
      decimals: asset?.decimals
    };

    if (type === "selectedAsset" && newAsset) {
      setSelectedAsset(newAsset);
    } else if (type === "selectedEarnedAsset" && newAsset) {
      setSelectedEarnedAsset(newAsset);
    }
  };

  // Use displayLabel for rendering, and label for actual value selection
  const menuPropsVolume = {
    items: items.map((item) => ({
      ...item,
      label: (
        <p onClick={() => handleSelectAsset(item, "selectedAsset")}>
          {item.displayLabel}
        </p>
      ),
    })),
  };

  const menuPropsEarnedPayout = {
    items: items.map((item) => ({
      ...item,
      label: (
        <p onClick={() => handleSelectAsset(item, "selectedEarnedAsset")}>
          {item.displayLabel}
        </p>
      ),
    })),
  };
  console.log('selectedEarnedAsset', selectedEarnedAsset);
  console.log('selectedAsset', selectedAsset);


  return (
    <div className="merchantView">
      <CustomBreadcrumb
        items={breadcrumbItems}
        activeIndex={2}
        activeClassName="active-breadcrumb-item"
      />
      <div className="merchantView_top">
        <div className="merchantView_top_detail commonCardBG">
          <div>
            <p>Merchant Name</p>
            <h4>{merchantDetails?.name}</h4>
          </div>
          <div>
            <p>API Key</p>
            <h4>
              {formatWalletAddress(merchantDetails?.api_key)}
              {merchantDetails?.api_key && merchantDetails?.api_key !== '' ?
                <span
                  onClick={() => {
                    handleCopy(merchantDetails?.api_key);
                  }}
                >
                  <CopyIcon />
                </span>
                : "N/A"}
            </h4>
          </div>
          {merchantDetails?.added_by == 'self' && (
            <div>
              <p>Date of Approval</p>
              <h4>{merchantDetails?.date_of_approval ? new Date(
                merchantDetails?.date_of_approval
              ).toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              }) + ' | ' + new Date(
                merchantDetails?.date_of_approval
              ).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }) : 'N/A'}</h4>
            </div>
          )}
        </div>
        <div className="earnedCard commonCardBG">
          <div className="earnedCard_icon">
            <EarnedPayoutsIcon />
          </div>
          <div>
            <Flex align="center" className="earnedCard_dropDown">
              <Dropdown menu={menuPropsEarnedPayout}>
                <Button>
                  <img
                    src={
                      selectedEarnedAsset.image != null &&
                        selectedEarnedAsset.image != ""
                        ? selectedEarnedAsset.image
                        : noImage
                    }
                    alt=""
                  />
                  <DownOutlined />
                </Button>
              </Dropdown>
              <h3>
                {earnedPayoutForMerchant?.total_crypto_amount ? exponentialToDecimal(formatNumber(earnedPayoutForMerchant?.total_crypto_amount, selectedEarnedAsset?.decimals)) : "0"}($
                {(
                  (earnedPayoutForMerchant?.total_crypto_amount || 0) *
                  (earnedPayoutForMerchant?.coin_data?.coin_price_in_fiat_data
                    ?.current_price || 0)
                ).toFixed(4) || "0"}
                )
              </h3>
            </Flex>
            <p>Earned Payouts</p>
          </div>
        </div>
        <div className="earnedCard commonCardBG">
          <div className="commonMonthBtn">
            {["1D", "1W", "1M", "1Y"].map((period) => (
              <button
                key={period}
                className={`filter-button ${filter === period ? "active" : ""}`}
                onClick={() => handleFilterChange(period)}
              >
                {period}
              </button>
            ))}
          </div>
          <div className="earnedCard_icon">
            <DollerSwapIcon />
          </div>
          <div>
            <div className="earnedCard_dropDown">
              <Dropdown menu={menuPropsVolume}>
                <Button>
                  <img
                    src={
                      selectedAsset.image != null && selectedAsset.image != ""
                        ? selectedAsset.image
                        : noImage
                    }
                    alt=""
                  />
                  <DownOutlined />
                </Button>
              </Dropdown>
              <h3>
                {userPaymentsVolumeForMerchant?.total_crypto_amount ? exponentialToDecimal(formatNumber(userPaymentsVolumeForMerchant?.total_crypto_amount, selectedAsset?.decimals)) : 0} ($
                {(
                  (userPaymentsVolumeForMerchant?.total_crypto_amount || 0) *
                  (userPaymentsVolumeForMerchant?.coin_data
                    ?.coin_price_in_fiat_data?.current_price || 0)
                )?.toFixed(4) || "0"}
                )
              </h3>
            </div>
            <p>Volume of user payments</p>
          </div>
        </div>
      </div>
      <div className="merchantView_middle">
        <div className="merchantView_middle_container commonCardBG">
          <div className="merchantView_middle_head">
            <h2>Withdrawal Wallet Addresses</h2>
            <p>{timeAgo ? `Last update: ${timeAgo}` : ""}</p>
          </div>
          {merchantDetails?.merchant_coin_data?.map((item) =>
            item?.merchant_coin_status == 1 ? (
              <div className="merchantView_middle_addresses" key={item.id}>
                <h4>
                  <img
                    src={
                      item?.coin_data?.coin_image != null
                        ? `${CONFIG.S3_URL}/${item?.coin_data?.coin_image}`
                        : `${noImage}`
                    }
                    alt={`${item.label} icon`}
                    height={30}
                    width={30}
                  />
                  {item?.coin_data?.coin_symbol?.toUpperCase()}
                  {item?.coin_data?.is_token === 1 ? "" : ""}
                  <small>
                    {item?.coin_data?.is_token === 1 &&
                      item?.coin_data?.coin_family ? (
                      <span className="coin-symbol">
                        ({coinFamilySymbols[item?.coin_data?.coin_family]})
                      </span>
                    ) : null}
                  </small>
                </h4>
                {item?.wallet_address_data ? (
                  <p>
                    {formatWalletAddress(
                      item?.wallet_address_data?.wallet_address
                    )}{" "}
                    <span
                      onClick={() => {
                        handleCopy(item?.wallet_address_data?.wallet_address);
                      }}
                    >
                      <CopyIcon />
                    </span>
                  </p>
                ) : (
                  "N/A"
                )}
              </div>
            ) : null
          )}
        </div>
        <div className="commonCardBG">
          <h2>KYB Documents</h2>
          <div className="KYBDocument">
            <div className="KYBDocument_card" onClick={handleOpenClick}>
              <h5>Merchant Logo</h5>
              <h6>View</h6>
            </div>
            <div
              className="KYBDocument_card"
              onClick={() => handleOpenClick(1)}
            >
              <h5>ID Front</h5>
              <h6>View</h6>
            </div>
            <div
              className="KYBDocument_card"
              onClick={() => handleOpenClick(2)}
            >
              <h5>ID Back</h5>
              <h6>View</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="enabledAssets commonCardBG">
        <h2>Enabled Assets</h2>
        <div className="enabledAssets_container">
          {merchantDetails?.merchant_coin_data?.map((asset) =>
            asset?.merchant_coin_status == 1 ? (
              <div key={asset.id}>
                <h4>
                  <img
                    src={
                      asset?.coin_data?.coin_image != null
                        ? `${CONFIG.S3_URL}/${asset?.coin_data?.coin_image}`
                        : `${noImage}`
                    }
                    alt={`${asset.label} icon`}
                    height={30}
                    width={30}
                  />
                  {asset?.coin_data?.coin_symbol?.toUpperCase()}
                  {asset?.coin_data?.is_token === 1 &&
                    asset?.coin_data?.coin_family ? (
                    <small>
                      (
                      <span className="coin-symbol">
                        {coinFamilySymbols[asset?.coin_data?.coin_family]}
                      </span>
                      )
                    </small>
                  ) : null}
                </h4>
              </div>
            ) : null
          )}
        </div>
      </div>
      <Modal
        open={isModalVisible}
        onOk={handleModalClose}
        centered
        onCancel={handleModalClose}
        footer={null}
        className="common-modal merchantLogo"
      >
        <div className="modal-content">
          <img
            src={
              merchantDetails?.logo != null
                ? `${CONFIG.S3_URL}/${merchantDetails?.logo}`
                : noImage
            }
            alt=""
            height={500}
            width={100}
          />
        </div>
      </Modal>
      <Modal
        open={isModalVisible2}
        onOk={handleModalClose2}
        centered
        onCancel={handleModalClose2}
        footer={null}
        className="common-modal merchantLogo"
      >
        <div className="modal-content">
          <img
            src={
              merchantDetails?.id_front_image != null
                ? `${CONFIG.S3_URL}/${merchantDetails?.id_front_image}`
                : noImage
            }
            alt=""
            height={500}
            width={100}
          />
        </div>
      </Modal>
      <Modal
        open={isModalVisible3}
        onOk={handleModalClose3}
        centered
        onCancel={handleModalClose3}
        footer={null}
        className="common-modal merchantLogo"
      >
        <div className="modal-content">
          <img
            src={
              merchantDetails?.id_back_image != null
                ? `${CONFIG.S3_URL}/${merchantDetails?.id_back_image}`
                : noImage
            }
            alt=""
            height={500}
            width={100}
          />
        </div>
      </Modal>
    </div>
  );
}

export default MerchantView;
