import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

import userSlice from "./slices/userSlice"; // Assuming you have a userSlice
import { authApi } from "./apiService/loginApi";
import settingApi from "./apiService/settingsApi";
import dashboardApi from "./apiService/dashboardApi";
import searchSlice from "./slices/searchSlice";
import currencyPreferencesApi from "./apiService/currencyPreferencesApi";
import merchantApi from "./apiService/merchantApi";
import transactionDetailsApi from "./apiService/transactionDetailsApi";
import { feeApiListApi } from "./apiService/feeApiList";
import payoutsApi from "./apiService/payoutsApi";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  user: userSlice,
  search: searchSlice,
  [authApi.reducerPath]: authApi.reducer,
  [settingApi.reducerPath]: settingApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [currencyPreferencesApi.reducerPath]: currencyPreferencesApi.reducer,
  [merchantApi.reducerPath]: merchantApi.reducer,
  [transactionDetailsApi.reducerPath]: transactionDetailsApi.reducer,
  [feeApiListApi.reducerPath]: feeApiListApi.reducer,
  [payoutsApi.reducerPath]: payoutsApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi?.middleware,
      settingApi?.middleware,
      dashboardApi?.middleware,
      currencyPreferencesApi?.middleware,
      merchantApi.middleware,
      transactionDetailsApi.middleware,
      feeApiListApi.middleware,
      payoutsApi.middleware
    ),
});

const persistor = persistStore(store);

export { store, persistor };
