import { message } from "antd";
import { logout } from "../../../Redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useApiErrorHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleApiError = (err) => {
    message.destroy();
    if (err?.status === "FETCH_ERROR") {
      message.error("Please check your internet connection");
    } else if (
      err?.status === 401 ||
      err?.data?.message === "Invalid Token, please try again."
    ) {
      dispatch(logout());
      message.error("Session timeout!");
      navigate("/login");
    } else if (err?.data?.message) {
      message.error(err?.data?.message);
    } else {
      console.error("Error:", err);
    }
  };

  return { handleApiError };
};

export default useApiErrorHandler;
