import React, { useState } from "react";
import { Radio } from "antd";
import "./RadioCustomstyle.scss";

function RadioCustom(props) {
  const { radioBtn, radioText } = props;

  return (
    <div>
      <Radio className={`radioCustom ${radioBtn ? "radioBtn" : ""}`} {...props}>
        {radioText ? <span className="radioText">{radioText}</span> : null}
      </Radio>
    </div>
  );
}

export default RadioCustom;
