import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../Components/Commons/constants/constant";

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.API_URL,
    prepareHeaders: (headers) => {
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("authorization", localStorage.getItem("jwt_token") || "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDashboardData: builder.mutation({
      query: (payload) => ({
        url: "dashboard",
        method: "POST",
        body: payload,
      }),
    }),
    getDashboardTxVolume: builder.mutation({
      query: (payload) => ({
        url: "dashboard/txnVolume",
        method: "POST",
        body: payload,
      }),
    }),
    getMerchantPaymentSummaryChart: builder.mutation({
      query: (payload) => ({
        url: "dashboard/merchantPaymentSummaryChart",
        method: "POST",
        body: payload,
      }),
    }),
    getRevenueChart: builder.mutation({
      query: (payload) => ({
        url: "dashboard/revenueChart",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetDashboardDataMutation,
  useGetDashboardTxVolumeMutation,
  useGetMerchantPaymentSummaryChartMutation,
  useGetRevenueChartMutation,
} = dashboardApi;
export default dashboardApi;
