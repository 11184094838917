import React from "react";
import OnboardAuthLayout from "../../Components/Layouts/OnboardLayout/OnboardAuthLayout";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { Path } from "../Constant/RoutePaths";

const Public = () => {
  const auth = localStorage.getItem("jwt_token");

  return auth ? (
    <Navigate to={Path.DASHBOARD} replace={true} /> // private
  ) : (
    <>
      <OnboardAuthLayout />
    </>
  );
};

export default Public;
