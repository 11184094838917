import React, { useEffect, useState } from "react";
import { Button, DatePicker, Dropdown, Space, Switch, Table } from "antd";
import CustomDropdown from "../../Ui/CustomDropdown/CustomDropdown";
import "./Payouts.scss";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { DownloadIcon, TableEyeIcon } from "../../../Assets/Assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { encryption } from "../../Commons/commonFunctions/encryption";
import { useCompletedPayoutsMerchantListMutation } from "../../../Redux/apiService/payoutsApi";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { useAssetsDropdownListQuery } from "../../../Redux/apiService/merchantApi";
import { DownOutlined } from "@ant-design/icons";
import { CONFIG } from "../../Commons/constants/constant";
import { useSelector } from "react-redux";
import { Path } from "../../../Routing/Constant/RoutePaths";
import noImage from "./../../../Assets/noImage.png";
import {
  calculateTimeAgo,
  coinFamilySymbols,
  exponentialToDecimal,
  formatNumber,
} from "../../Commons/commonFunctions/commonFunctions";
import { CalendarFilled } from "@ant-design/icons";
import dayjs from "dayjs";

function CompletedRequests() {
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const [trxList, setTrxList] = useState();
  const [completedPayoutsMerchantList] =
    useCompletedPayoutsMerchantListMutation();
  const { data: assetsData } = useAssetsDropdownListQuery();
  const [items, setItems] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const location = useLocation();
  const currentLocation = location.pathname;
  const searchBarValue = useSelector((state) => state.search.searchValue);
  // const timeZone = useSelector((state) => state?.user?.time_zone);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timeAgo, setTimeAgo] = useState();

  const { handleApiError } = useApiErrorHandler();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const onChange = (dates, dateStrings) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  const completedPayoutsList = async (resetPage = false) => {
    setLoading(true);
    let pageToFetch = resetPage ? 1 : pagination.current;
    let payloadData = {
      page: pageToFetch,
      limit: pagination?.pageSize,
    };

    if (selectedAsset) {
      payloadData.coinId = selectedAsset?.id;
    }
    if (startDate && endDate) {
      payloadData.startDate = startDate;
      payloadData.endDate = endDate;
    }
    if (currentLocation === Path.PAYOUTS && searchBarValue !== "") {
      payloadData.search = searchBarValue;
    }
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await completedPayoutsMerchantList(securedData).unwrap();
      if (data?.status === true) {
        setTrxList(data?.data);
        // const updatedTime = data?.data?.latest_payout_date;
        // setTimeAgo(calculateTimeAgo(updatedTime));
        setPagination({
          ...pagination,
          current: data?.meta?.page,
          total: data?.meta?.total || 0,
        });
        // setTotalPaymentsAmount(data?.totalFiatAmount);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedAsset?.id) {
      setPagination((prev) => ({ ...prev, current: 1 }));
      completedPayoutsList(true);
    }
  }, [searchBarValue]);

  useEffect(() => {
    if (selectedAsset?.id) {
      completedPayoutsList();
    }
  }, [selectedAsset, startDate, endDate]);

  const handleViewDetails = (record) => {
    navigate(`viewDetails`, { state: { record, asset: selectedAsset } });
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination?.current,
    });
  };

  const menuProps = {
    items,
  };

  const handleAssetSelect = (asset) => {
    setSelectedAsset({
      id: asset.id,
      image:
        asset?.coin_image != null
          ? `${CONFIG.S3_URL}/${asset?.coin_image}`
          : `${noImage}`,
      isToken: asset?.is_token,
      name: asset.coin_name,
      decimals: asset.decimal_value,
    });

    // Reset pagination to page 1
    setPagination((prev) => ({ ...prev, current: 1 }));
  };
  useEffect(() => {
    if (assetsData && assetsData?.status === true) {
      let formattedItems = [];
      let i = 0;
      for (const asset of assetsData?.data) {
        //const formattedItems = assetsData?.data?.map((asset) => {
        if (i == 0) {
          setSelectedAsset({
            id: asset.id,
            image:
              asset?.coin_image != null
                ? `${CONFIG.S3_URL}/${asset?.coin_image}`
                : `${noImage}`,
            isToken: asset?.is_token,
            name: asset.coin_name,
            decimals: asset.decimal_value,
          });
        }

        let data = {
          label: (
            <p onClick={() => handleAssetSelect(asset)}>
              <img
                src={
                  asset.coin_image != null
                    ? `${CONFIG.S3_URL}/${asset.coin_image}`
                    : `${noImage}`
                }
                alt={`${asset.coin_name} icon`}
                height={20}
                width={20}
              />{" "}
              {asset?.coin_name}{" "}
              <small>
                {asset?.is_token === 1 && asset?.coin_family ? (
                  <span className="coin-symbol">
                    ({coinFamilySymbols[asset?.coin_family]})
                  </span>
                ) : null}
              </small>
            </p>
          ),
          key: asset.id,
        };
        formattedItems.push(data);
        i++;
      }
      //});
      setItems(formattedItems);
    }
  }, [assetsData]);
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Merchant Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Merchant Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Total Payments to Merchant",
      dataIndex: "total_crypto_amount",
      key: "total_crypto_amount",
      render: (_, amount) => {
        const date = new Date(amount?.latest_payout_date);
        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        });
        return (
          <div>
            <div>{amount?.total_crypto_amount ? exponentialToDecimal(formatNumber(amount?.total_crypto_amount, selectedAsset?.decimals)) : 0}</div>
            <div style={{ fontSize: "small", color: "#888888" }}>
              Last Release: {formattedDate}
            </div>
          </div>
        );
      },
    },
    {
      title: "Total Admin Commission Earned",
      dataIndex: "total_commission_fee_in_crypto",
      key: "total_commission_fee_in_crypto",
      render: (_, amount) => (
        <div>{`${amount?.total_commission_fee_in_crypto ? exponentialToDecimal(formatNumber(
          amount?.total_commission_fee_in_crypto, selectedAsset?.decimals)) : 0} `}</div>
      ),
    },
    {
      title: "Currency",
      dataIndex: "coin_symbol",
      key: "coin_symbol",
      render: (_, data) => (
        <div>
          {`${data?.coin_symbol?.toUpperCase()}`}
          {data?.is_token === 1 && data?.coin_family ? (
            <span> ({coinFamilySymbols[data?.coin_family]})</span>
          ) : null}
        </div>
      ),
    },

    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (text, record) => (
        <span
          onClick={() => handleViewDetails(record)} // Pass the merchant ID on click
          style={{ cursor: "pointer" }}
        >
          <TableEyeIcon />
        </span>
      ),
    },
  ];

  const downloadList = () => {
    const baseUrl = `${CONFIG.API_URL}payouts/download`;

    const params = new URLSearchParams({
      page: pagination?.current,
      limit: pagination?.pageSize,
    });

    if (startDate && endDate) {
      params.append("startDate", startDate);
      params.append("endDate", endDate);
    }

    if (selectedAsset) {
      params.append("coinId", selectedAsset?.id);
    }

    if (currentLocation === Path.PAYOUTS && searchBarValue !== "") {
      params.append("search", searchBarValue);
    }

    const link = `${baseUrl}?${params.toString()}`;
    window.open(link);
  };

  const resetFilterHandler = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedAsset(null);
  };
  return (
    <div className="completedRequests">
      <div className="completedRequests_top">
        <div className="completedRequests_top">
          <RangePicker
            onChange={onChange}
            suffixIcon={<CalendarFilled style={{ color: "black" }} />}
            value={
              startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []
            }
          />
          <Dropdown menu={menuProps}>
            <Button>
              {selectedAsset ? (
                <img src={selectedAsset.image} alt="" height={20} width={20} />
              ) : (
                ""
              )}
              {selectedAsset?.name || "Filter By Asset"}
              <DownOutlined />
            </Button>
          </Dropdown>
          {/* <Button onClick={resetFilterHandler}>
            <Space>Reset Filters</Space>
          </Button> */}
        </div>
        <ButtonCustom
          leftIcon={<DownloadIcon />}
          label="Download CSV"
          className="downloadBtn"
          onClick={downloadList}
          disabled={trxList?.length < 1}
        />
      </div>
      <div className="UserTable">
        <Table
          dataSource={trxList}
          columns={columns}
          loading={loading}
          tableLayout={"auto"}
          scroll={{ x: "max-content" }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: false, // This will hide the "Select per page" dropdown
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
}

export default CompletedRequests;
