import React from "react";
import { Input, Select } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import "./Inputstyle.scss";

function InputCustom(props) {
  const {
    textInput,
    maxLength,
    fileInput,
    eyeicon,
    passwordInput,
    basicInput,
    inputInnerTxt,
    innerTxt,
    placeholder,
    inputInnerLargeTxt,
    innerTxtImg,
    innerImg,
    searchImg,
    inputSearch,
    label,
    labelcustom,
    InputCustomStyle,
    fillBg,
    onChange,
    value,
    disabled,
    editText,
    onClickDiv,
    showView,
    required,
    phoneInput, 
    phoneValue,
    countryCode,
    onCountryCodeChange,
    onPhoneChange,
  } = props;

  const countryCodes = [
    { label: "+91", value: "+91" },
    { label: "+1", value: "+1" },
    { label: "+44", value: "+44" },
  ];

  return (
    <div className="inputLayout">
      {basicInput ? (
        <>
          {label ? (
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <label className={`label ${labelcustom}`}>
                {label}{" "}
                {required && <span className="required-asterisk">*</span>}
              </label>
              {editText && (
                <div
                  onClick={onClickDiv}
                  style={{ justifyContent: "flex-end" }}
                  className={`label ${labelcustom} ${
                    showView ? "texteditLight" : "textedit"
                  }`}
                >
                  {editText}
                </div>
              )}
            </div>
          ) : null}
          <Input
            maxLength={maxLength || 25}
            className={`inputCustum ${inputInnerTxt ? "inputInnerTxt" : ""}${
              inputInnerLargeTxt ? "inputInnerLargeTxt" : ""
            }${inputSearch ? "inputSearch" : ""} ${InputCustomStyle} ${fillBg}`}
            {...props}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            disabled={disabled}
            onWheel={() => document.activeElement.blur()}
          />
        </>
      ) : null}

      {innerTxt ? <span className="innerTxt">{innerTxt}</span> : null}
      {innerTxtImg ? (
        <span className="innerTxtImg">
          {innerTxtImg}
          {innerImg}{" "}
        </span>
      ) : null}
      {searchImg ? <span className="searchImg">{searchImg}</span> : null}

      {passwordInput ? (
        <>
          {label ? (
            <label className="label">
              {label} {required && <span className="required-asterisk">*</span>}
            </label>
          ) : null}
          <Input.Password
            {...props}
            maxLength={maxLength || 25}
            placeholder={placeholder}
            className={`inputCustum ${eyeicon ? "eyeicon" : ""}`}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </>
      ) : null}

      {textInput ? (
        <>
          {label ? (
            <label className="label">
              {label} {required && <span className="required-asterisk">*</span>}
            </label>
          ) : null}
          <Input.TextArea
            {...props}
            rows={4}
            maxLength={maxLength || 25}
            placeholder={placeholder}
            className={`inputCustum`}
          />
        </>
      ) : null}

      {fileInput ? (
        <>
          {label ? (
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <label className={`label1 ${labelcustom}`}>
                {label}{" "}
                {required && <span className="required-asterisk">*</span>}
              </label>
              {editText && (
                <div
                  onClick={onClickDiv}
                  style={{ justifyContent: "flex-end" }}
                  className={`label1 ${labelcustom} ${
                    showView ? "texteditLight" : "textedit"
                  }`}
                >
                  {editText}
                </div>
              )}
            </div>
          ) : null}
          <Input
            type="file"
            className={`inputCustum ${InputCustomStyle}`}
            onChange={onChange}
            disabled={disabled}
          />
        </>
      ) : null}

      {phoneInput ? (
        <div className="phoneInputWrapper">
          {label ? (
            <label className="label">
              {label} {required && <span className="required-asterisk">*</span>}
            </label>
          ) : null}
          <div className="phoneInputWrapper_select">
            <Select
              defaultValue={countryCode || "+91"}
              onChange={onCountryCodeChange}
              style={{ width: 80 }}
              options={countryCodes}
            />
            <Input
              className="inputCustum"
              value={phoneValue}
              onChange={onPhoneChange}
              placeholder="Enter phone number"
              maxLength={15}
              style={{ flex: 1 }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default InputCustom;
