import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../Components/Commons/constants/constant";

export const merchantApi = createApi({
  reducerPath: "merchantApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.API_URL,
    prepareHeaders: (headers) => {
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("authorization", localStorage.getItem("jwt_token") || "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    merchantList: builder.mutation({
      query: (payload) => ({
        url: "merchant/list",
        method: "POST",
        body: payload,
      }),
    }),
    merchantListStatusUpdate: builder.mutation({
      query: (payload) => ({
        url: "merchant/approveStatus",
        method: "POST",
        body: payload,
      }),
    }),
    addMerchantFromAdmin: builder.mutation({
      query: (payload) => ({
        url: "merchant/add",
        method: "POST",
        body: payload,
      }),
    }),
    editMerchantFromAdmin: builder.mutation({
      query: (payload) => ({
        url: "merchant/edit",
        method: "POST",
        body: payload,
      }),
    }),
    merchantViewDetails: builder.mutation({
      query: (payload) => ({
        url: "merchant/view",
        method: "POST",
        body: payload,
      }),
    }),
    //changeApiKeyStatus
    changeApiKeyStatus: builder.mutation({
      query: (payload) => ({
        url: "merchant/changeApiKeyStatus",
        method: "POST",
        body: payload,
      }),
    }),
    earnedPayouts: builder.mutation({
      query: (payload) => ({
        url: "merchant/earnedPayouts",
        method: "POST",
        body: payload,
      }),
    }),
    userPaymentsVolume: builder.mutation({
      query: (payload) => ({
        url: "merchant/userPaymentsVolume",
        method: "POST",
        body: payload,
      }),
    }),
    imageUpload: builder.mutation({
      query: (formData) => ({
        url: "merchant/uploadFile",
        method: "POST",
        body: formData,
      }),
    }),
    assetsDropdownList: builder.query({
      query: () => ({
        url: "merchant/coinList",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddMerchantFromAdminMutation,
  useEditMerchantFromAdminMutation,
  useMerchantViewDetailsMutation,
  useMerchantListMutation,
  useChangeApiKeyStatusMutation,
  useEarnedPayoutsMutation,
  useUserPaymentsVolumeMutation,
  useImageUploadMutation,
  useAssetsDropdownListQuery,
  useMerchantListStatusUpdateMutation
} = merchantApi;
export default merchantApi;
