import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { Button, Dropdown, Flex } from "antd";
import {
  UserGroupIcon,
  NotAllowedIcon,
  DollerSwapIcon,
  PendingIcon,
  AcceptedIcon,
  RejectedIcon,
  EarnedIcon,
  GraphIcon,
  ActiveMerchantsNew,
} from "../../../Assets/Assets";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Area,
  AreaChart,
  Label,
} from "recharts";
import {
  useGetDashboardDataMutation,
  useGetDashboardTxVolumeMutation,
  useGetMerchantPaymentSummaryChartMutation,
  useGetRevenueChartMutation,
} from "../../../Redux/apiService/dashboardApi";
import { encryption } from "../../Commons/commonFunctions/encryption";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { useSelector } from "react-redux";
import { CONFIG } from "../../Commons/constants/constant";
import { DownOutlined } from "@ant-design/icons";
import { useAssetsDropdownListQuery } from "../../../Redux/apiService/merchantApi";
import { coinFamilySymbols, exponentialToDecimal, formatNumber } from "../../Commons/commonFunctions/commonFunctions";
import noImage from "./../../../Assets/noImage.png";
import NoData from "../../Commons/noData/NoData";

const Dashboard = () => {
  const [filter, setFilter] = useState("1D");
  const [filterRevenue, setFilterRevenue] = useState("1D");
  const [txVolumeTimeline, setTxVolumeTimeline] = useState("1D");

  const [getDashboardData] = useGetDashboardDataMutation();
  const [getDashboardTxVolume] = useGetDashboardTxVolumeMutation();
  const [getMerchantPaymentSummaryChart] =
    useGetMerchantPaymentSummaryChartMutation();
  const [getRevenueChart] = useGetRevenueChartMutation();
  const [totalMerchants, setTotalMerchants] = useState(0);
  const [activeMerchants, setActiveMerchants] = useState(0);
  const [inactiveMerchants, setInactiveMerchants] = useState(0);
  const [paymentSummaryChartData, setPaymentSummaryChartData] = useState();
  const [revenueChartData, setRevenueChartData] = useState();
  const [items, setItems] = useState([]);
  // const [selectedAsset, setSelectedAsset] = useState({
  //   id: 1,
  //   image: `${CONFIG.S3_URL}/triskel-pay/btc.png`,
  // });
  const [selectedAssetForTxVolume, setSelectedAssetForTxVolume] = useState({
    id: 1,
    image: `${CONFIG.S3_URL}/triskel-pay/btc.png`,
    decimals: 8,
    coinFamily: 1,
    isToken: 0
  });
  const [selectedAssetForPaymentSummary, setSelectedAssetForPaymentSummary] =
    useState({
      id: 1,
      image: `${CONFIG.S3_URL}/triskel-pay/btc.png`,
      decimals: 8,
      coinFamily: 1,
      isToken: 0
    });
  const [selectedAssetForRevenueChart, setSelectedAssetForRevenueChart] =
    useState({
      id: 1,
      image: `${CONFIG.S3_URL}/triskel-pay/btc.png`,
      decimals: 8,
      coinFamily: 1,
      isToken: 0
    });
  const [txVolume, setTxVolume] = useState();
  const { handleApiError } = useApiErrorHandler();
  const timeZone = useSelector((state) => state?.user?.time_zone);
  const { data: assetsData, refetch } = useAssetsDropdownListQuery();

  const handleSelectAsset = (asset, type) => {
    const newAsset = {
      id: asset?.id,
      image: asset?.image,
      isToken: asset?.isToken,
      coinFamily: asset?.coinFamily,
      decimals: asset?.decimals,
    };

    if (type === "txVolume" && newAsset) {
      setSelectedAssetForTxVolume(newAsset);
    } else if (type === "paymentSummary" && newAsset) {
      setSelectedAssetForPaymentSummary(newAsset);
    } else if (type === "revenueChart" && newAsset) {
      setSelectedAssetForRevenueChart(newAsset);
    }
  };
  useEffect(() => {
    if (assetsData && assetsData.status === true) {
      // Format items to include both displayLabel (for UI) and label (for selection)
      const formattedItems = assetsData?.data?.map((asset) => ({
        id: asset.id,
        label: asset?.coin_name, // Use a plain label for dropdown selection
        value: asset?.coin_name, // Use a plain value for dropdown selection
        image: asset?.coin_image
          ? `${CONFIG.S3_URL}/${asset.coin_image}`
          : `${noImage}`,
        isToken: asset?.is_token,
        coinFamily: asset?.coin_family,
        decimals: asset?.decimal_value,
        displayLabel: (
          <p>
            <img
              src={
                asset.coin_image != null
                  ? `${CONFIG.S3_URL}/${asset.coin_image}`
                  : `${noImage}`
              }
              alt={`${asset.coin_name} icon`}
              height={20}
              width={20}
            />
            {asset?.coin_name}
            {asset?.is_token === 1 && asset?.coin_family ? (
              <small className="coin-symbol">
                ({coinFamilySymbols[asset?.coin_family]})
              </small>
            ) : null}
          </p>
        ),
      }));

      setItems(formattedItems);
    }
  }, [assetsData]);

  useEffect(() => {
    refetch();
  }, []);

  const merchantsData = [
    {
      count: totalMerchants,
      label: "Total Merchants",
      icon: <UserGroupIcon />,
    },
    {
      count: activeMerchants,
      label: "Active Merchants",
      icon: <ActiveMerchantsNew />,
    },
    {
      count: inactiveMerchants,
      label: "Inactive Merchants",
      icon: <NotAllowedIcon />,
    },
    {
      count:
        (txVolume?.total_crypto_amount ? exponentialToDecimal(formatNumber(txVolume?.total_crypto_amount, selectedAssetForTxVolume?.decimals)) : "0") +
        "($" +
        (txVolume?.coin_data
          ? (
            txVolume?.total_crypto_amount *
            txVolume?.coin_data?.coin_price_in_fiat_data?.current_price
          ).toFixed(4)
          : "0") +
        ")",
      label: "Transaction Volume",
      icon: <DollerSwapIcon />,
      hasDropdown: true,
    },
  ];
  console.log('selectedAssetForTxVolume >>.', selectedAssetForTxVolume);
  const handleFilterChange = (newFilter, type) => {
    if (type === "revenue") {
      setFilterRevenue(newFilter);
    } else if (type === "payment") {
      setFilter(newFilter);
    } else if (type === "txVolume") {
      setTxVolumeTimeline(newFilter);
    }
  };

  const fetchDashboardCardData = async () => {
    try {
      const data = await getDashboardData().unwrap();
      if (data?.status === true) {
        setActiveMerchants(data?.data?.activeMerchants);
        setInactiveMerchants(data?.data?.inactiveMerchants);
        setTotalMerchants(data?.data?.totalMerchants);
      }
    } catch (error) {
      handleApiError(error); // Use the utility function for common API error handling
    }
  };

  const fetchDashboardCardTxVolume = async () => {
    try {
      const enc = await encryption(
        JSON.stringify({
          timeline: txVolumeTimeline,
          coinId: selectedAssetForTxVolume?.id,
        })
      );
      const securedData = { dataString: enc };
      const data = await getDashboardTxVolume(securedData).unwrap();
      if (data?.status === true) {
        setTxVolume(data?.data);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchDashboardPaymentSummaryCardData = async () => {
    try {
      const enc = await encryption(
        JSON.stringify({
          timeline: filter,
          timeZone,
          coinId: selectedAssetForPaymentSummary?.id,
        })
      );
      const securedData = { dataString: enc };
      const data = await getMerchantPaymentSummaryChart(securedData).unwrap();
      if (data?.status === true) {
        setPaymentSummaryChartData(data?.data);
      }
    } catch (error) {
      handleApiError(error); // Use the utility function for common API error handling
    }
  };

  const fetchDashboardRevenueChartData = async () => {
    try {
      const enc = await encryption(
        JSON.stringify({
          timeline: filterRevenue,
          timeZone,
          coinId: selectedAssetForRevenueChart?.id,
        })
      );
      const securedData = { dataString: enc };
      const data = await getRevenueChart(securedData).unwrap();
      if (data?.status === true) {
        setRevenueChartData(data?.data);
      }
    } catch (error) {
      handleApiError(error); // Use the utility function for common API error handling
    }
  };

  useEffect(() => {
    fetchDashboardCardData();
  }, []);
  useEffect(() => {
    setPaymentSummaryChartData([]);
    fetchDashboardPaymentSummaryCardData();
  }, [filter, selectedAssetForPaymentSummary]);
  useEffect(() => {
    fetchDashboardRevenueChartData();
  }, [filterRevenue, selectedAssetForRevenueChart]);
  useEffect(() => {
    fetchDashboardCardTxVolume();
  }, [txVolumeTimeline, selectedAssetForTxVolume]);

  const chartData = paymentSummaryChartData?.map((item) => {
    switch (filter) {
      case "1D":
        return {
          hour: new Date(item?.hour_interval).getHours() + ":00", // Extract the hour from the timestamp
          Pending:
            item?.pending_amount > 0
              ? item?.pending_amount
              : item?.pending_amount,
          Accepted:
            item?.approve_amount > 0
              ? item?.approve_amount
              : item?.approve_amount,
          Rejected:
            item?.reject_amount > 0 ? item?.reject_amount : item?.reject_amount,
        };
      case "1W":
        return {
          hour: item?.day_name, // Use day name for weekly data
          Pending:
            item?.pending_amount > 0
              ? item?.pending_amount
              : item?.pending_amount,
          Accepted:
            item?.approve_amount > 0
              ? item.approve_amount
              : item?.approve_amount,
          Rejected:
            item.reject_amount > 0 ? item.reject_amount : item.reject_amount,
        };
      case "1M":
        return {
          hour: item?.date_interval, // Use date for monthly data
          Pending:
            item.pending_amount > 0 ? item.pending_amount : item.pending_amount,
          Accepted:
            item.approve_amount > 0 ? item.approve_amount : item.approve_amount,
          Rejected:
            item.reject_amount > 0 ? item.reject_amount : item.reject_amount,
        };
      case "1Y":
        return {
          hour: item?.month_name, // Use month name for yearly data
          Pending:
            item.pending_amount > 0 ? item.pending_amount : item.pending_amount,
          Accepted:
            item.approve_amount > 0 ? item.approve_amount : item.approve_amount,
          Rejected:
            item.reject_amount > 0 ? item.reject_amount : item.reject_amount,
        };
      default:
        return {};
    }
  });

  const lineGraphData = revenueChartData?.map((item) => {
    switch (filterRevenue) {
      case "1D":
        return {
          hour: new Date(item.hour_interval).getHours() + ":00", // Extract the hour from the timestamp
          Commissions: item.commission_amount,
          Payments: item.payment_amount,
          CurrentPrice: item.current_price,
        };
      case "1W":
        return {
          hour: item?.day_name, // Use day name for weekly data
          Commissions: item.commission_amount,
          Payments: item.payment_amount,
          CurrentPrice: item.current_price,
        };
      case "1M":
        return {
          hour: item?.date_interval, // Use date for monthly data
          Commissions: item.commission_amount,
          Payments: item.payment_amount,
          CurrentPrice: item.current_price,
        };
      case "1Y":
        return {
          hour: item?.month_name, // Use month name for yearly data
          Commissions: item.commission_amount,
          Payments: item.payment_amount,
          CurrentPrice: item.current_price,
        };
      default:
        return {}; // Return an empty object if no valid filter
    }
  });

  const calculateTotals = (data, filterType) => {
    let totalPending = 0;
    let totalApproved = 0;
    let totalRejected = 0;
    let totalCommissions = 0;
    let totalPayments = 0;


    data.forEach((item) => {
      if (filterType === "payment") {
        totalPending += item.pending_amount * item?.current_price || 0;
        totalApproved += item.approve_amount * item?.current_price || 0;
        totalRejected += item.reject_amount * item?.current_price || 0;
      } else if (filterType === "revenue") {
        totalCommissions += item.Commissions * item?.CurrentPrice || 0;
        totalPayments += item.Payments * item?.CurrentPrice || 0;
      }
    });

    totalPending = totalPending.toFixed(4);
    totalApproved = totalApproved.toFixed(4);
    totalRejected = totalRejected.toFixed(4);
    totalCommissions = totalCommissions.toFixed(4);
    totalPayments = totalPayments.toFixed(4);

    return {
      totalPending,
      totalApproved,
      totalRejected,
      totalCommissions,
      totalPayments,
    };
  };

  const totals = calculateTotals(paymentSummaryChartData || [], "payment");
  const revenueTotals = calculateTotals(lineGraphData || [], "revenue");

  const menuPropsTxVolume = {
    items: items.map((item) => ({
      ...item,
      label: (
        <p onClick={() => handleSelectAsset(item, "txVolume")}>
          {item.displayLabel}
        </p>
      ),
    })),
  };

  const menuPropsPaymentSummary = {
    items: items.map((item) => ({
      ...item,
      label: (
        <p onClick={() => handleSelectAsset(item, "paymentSummary")}>
          {item.displayLabel}
        </p>
      ),
    })),
  };

  const menuPropsRevenueChart = {
    items: items.map((item) => ({
      ...item,
      label: (
        <p onClick={() => handleSelectAsset(item, "revenueChart")}>
          {item.displayLabel}
        </p>
      ),
    })),
  };

  return (
    <div className="dashboard">
      <Flex gap={24} wrap className="merchantsCard ">
        {merchantsData?.map((data, index) => (
          <div key={index} className="merchantsCard_items commonCardBG">
            {index === merchantsData.length - 1 && (
              <div className="commonMonthBtn">
                {["1D", "1W", "1M", "1Y"].map((period) => (
                  <button
                    style={{ marginBottom: "30px" }}
                    key={period}
                    className={`filter-button ${txVolumeTimeline === period ? "active" : ""
                      }`}
                    onClick={() => handleFilterChange(period, "txVolume")}
                  >
                    {period}
                  </button>
                ))}
              </div>
            )}
            <div className="merchantsCard_icon">{data.icon}</div>
            <div className="">
              <div className="merchantsCard_detail">
                <Flex align="center" gap={10}>
                  {data.hasDropdown && (
                    <Dropdown className="customDrop" menu={menuPropsTxVolume}>
                      <Button>
                        <img
                          src={
                            selectedAssetForTxVolume.image != null &&
                              selectedAssetForTxVolume.image != ""
                              ? selectedAssetForTxVolume.image
                              : noImage
                          }
                          alt=""
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        />
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  )}
                  <h3>{data.count}</h3>
                </Flex>
                <p>{data.label}</p>
              </div>
            </div>
          </div>
        ))}
      </Flex>
      <div className="dashboard_graph">
        <div className="merchantPayment">
          <div className="merchantPayment_head">
            <h3>Merchant Payment Summary</h3>
            <Flex>
              <Dropdown className="customDrop" menu={menuPropsPaymentSummary}>
                <Button>
                  <img
                    src={selectedAssetForPaymentSummary?.image}
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                  <DownOutlined />
                </Button>
              </Dropdown>
              <div className="commonMonthBtn">
                {["1D", "1W", "1M", "1Y"].map((period) => (
                  <button
                    key={period}
                    className={`filter-button ${filter === period ? "active" : ""
                      }`}
                    onClick={() => handleFilterChange(period, "payment")}
                  >
                    {period}
                  </button>
                ))}
              </div>
            </Flex>
          </div>
          <div className="graphCard">
            <div className="graphCard_item">
              <div className="merchantsCard_icon">
                <PendingIcon />
              </div>
              <div className="merchantsCard_detail">
                <h3>${totals.totalPending}</h3>
                <p>Pending</p>
              </div>
            </div>
            <div className="graphCard_item">
              <div className="merchantsCard_icon">
                <AcceptedIcon />
              </div>
              <div className="merchantsCard_detail">
                <h3>${totals.totalApproved}</h3>
                <p>Accepted</p>
              </div>
            </div>
            <div className="graphCard_item">
              <div className="merchantsCard_icon">
                <RejectedIcon />
              </div>
              <div className="merchantsCard_detail">
                <h3>${totals.totalRejected}</h3>
                <p>Rejected</p>
              </div>
            </div>
          </div>
          <div className="dashboard_graph_section">
            {paymentSummaryChartData?.length > 0 ? (
              <BarChart width={600} height={300} data={chartData}>
                <XAxis dataKey="hour" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Pending" fill="#f5b342" />
                <Bar dataKey="Accepted" fill="#71d613" />
                <Bar dataKey="Rejected" fill="#e00d0d" />
              </BarChart>
            ) : (
              <NoData />
            )}
          </div>
        </div>

        <div className="revenueChart">
          <div className="revenueChart_head">
            <h3>Revenue Chart</h3>
            <Flex>
              <Dropdown className="customDrop" menu={menuPropsRevenueChart}>
                <Button>
                  <img
                    src={selectedAssetForRevenueChart?.image}
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                  <DownOutlined />
                </Button>
              </Dropdown>
              <div className="commonMonthBtn">
                {["1D", "1W", "1M", "1Y"].map((period) => (
                  <button
                    key={period}
                    className={`filter-button ${filterRevenue === period ? "active" : ""
                      }`}
                    onClick={() => handleFilterChange(period, "revenue")}
                  >
                    {period}
                  </button>
                ))}
              </div>
            </Flex>
          </div>
          <div className="graphCard">
            <div className="graphCard_item">
              <div className="merchantsCard_icon">
                <EarnedIcon />
              </div>
              <div className="merchantsCard_detail">
                <h3>${revenueTotals.totalCommissions}</h3>
                <p>Commission Earned</p>
              </div>
            </div>
            <div className="graphCard_item">
              <div className="merchantsCard_icon">
                <GraphIcon />
              </div>
              <div className="merchantsCard_detail">
                <h3>${revenueTotals.totalPayments}</h3>
                <p>Merchant Payments</p>
              </div>
            </div>
          </div>
          <div className="dashboard_graph_section">
            {lineGraphData?.length > 0 ? (
              <AreaChart width={700} height={300} data={lineGraphData}>
                <XAxis dataKey={"hour"} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="Commissions"
                  stroke="#71d613"
                  fill="#71d613"
                  fillOpacity={0.3}
                />
                <Area
                  type="monotone"
                  dataKey="Payments"
                  stroke="#f5b342"
                  fill="#f5b342"
                  fillOpacity={0.3}
                />
              </AreaChart>
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
