import { Modal, Switch, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { TableEyeIcon } from "../../../Assets/Assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Path } from "../../../Routing/Constant/RoutePaths";
import { encryption } from "../../Commons/commonFunctions/encryption";
import {
  useChangeApiKeyStatusMutation,
  useMerchantListMutation,
  useMerchantListStatusUpdateMutation,
} from "../../../Redux/apiService/merchantApi";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { useSelector } from "react-redux";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";

function MerchantDetailsTable() {
  const navigate = useNavigate();
  const [merchantList] = useMerchantListMutation();
  const [merchantListStatusUpdate] = useMerchantListStatusUpdateMutation();
  const [changeApiKeyStatus] = useChangeApiKeyStatusMutation();

  const [merchantListOfWebsite, setMerchantListOfWebsite] = useState([]);
  const { handleApiError } = useApiErrorHandler();
  const location = useLocation();
  const currentLocation = location.pathname;
  const searchBarValue = useSelector((state) => state.search.searchValue);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  console.log("selectedStatus", selectedStatus);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const handleViewClick = (merchant) => {
    navigate("/merchant/merchantView", { state: { merchant } });
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const fetchMerchantsList = async (resetPage = false) => {
    setLoading(true);
    let pageToFetch = resetPage ? 1 : pagination.current;
    let payloadData = {
      page: pageToFetch,
      limit: pagination?.pageSize,
      type: "self",
    };
    if (currentLocation === Path.MERCHANT && searchBarValue !== "") {
      payloadData.search = searchBarValue;
    }

    try {
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await merchantList(securedData).unwrap();
      if (data?.status === true) {
        setMerchantListOfWebsite(data?.data);
        setPagination({
          ...pagination,
          current: data?.meta?.page,
          total: data?.meta?.total || 0,
        });
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination?.current,
    });
  };

  const handleStatusUpdate = async () => {
    setLoading(true); // Start the loader
    setDisableBtn(true);
    setIsModalVisible(false);
    let payloadData;

    // Single item case
    payloadData = {
      merchantId: selectedItem?.id,
      status: selectedStatus,
    };

    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await merchantListStatusUpdate(securedData).unwrap();
      if (data?.status === true) {
        message.success(data?.message);
        fetchMerchantsList(); // Refresh the list after success
        setDisableBtn(false);
      }
    } catch (error) {
      handleApiError(error);
      setLoading(false); // Stop the loader
      setDisableBtn(false);
    }
  };

  const handleRequest = async (item, status) => {
    try {
      console.log("ITEM FOR REQUEST", item, "and status", status);
      setSelectedStatus(status);
      setSelectedItem(item);
      setIsModalVisible(true);
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    fetchMerchantsList(false);
  }, [pagination?.current]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, current: 1 }));
    fetchMerchantsList(true);
  }, [searchBarValue]);

  const onSwitchChange = async (checked, merchant) => {
    setLoading(true);
    const newStatus = checked ? 1 : 0;
    let payloadData = {
      id: merchant?.id,
      status: newStatus,
    };
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await changeApiKeyStatus(securedData).unwrap();
      if (data?.status === true) {
        fetchMerchantsList();
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Merchant Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Date of Application",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        if (!created_at) return "N/A"; // Handle case if no date is present

        const date = new Date(created_at);

        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        });

        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        return `${formattedDate} | ${formattedTime}`;
      },
    },
    {
      title: "Platform Name",
      dataIndex: "platform_name",
      key: "platform_name",
      render: (platform_name) => (platform_name ? platform_name : "N/A"),
    },
    {
      title: "Date of Approval",
      dataIndex: "date_of_approval",
      key: "date_of_approval",
      render: (date_of_approval) => {
        if (!date_of_approval) return "N/A"; // Handle case if no date is present

        const date = new Date(date_of_approval);

        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        });

        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        return `${formattedDate} | ${formattedTime}`;
      },
    },
    {
      title: "API Key",
      dataIndex: "api_key_status",
      key: "api_key_status",
      render: (api_key_status, merchant) => (
        <Switch
          checked={api_key_status === 1}
          onChange={(checked) => onSwitchChange(checked, merchant)}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, item) => (
        <div className="status">
          {item?.status == 0 ? (
            <div className="status">
              <div className="accept">
                <span
                  onClick={() => handleRequest(item, 1)}
                  style={{ cursor: "pointer" }}
                  disabled={setLoading}
                >
                  Approve
                </span>
              </div>
              <div className="reject">
                <span
                  onClick={() => handleRequest(item, 2)}
                  style={{ cursor: "pointer" }}
                  disabled={setLoading}
                >
                  Reject
                </span>
              </div>
            </div>
          ) : item?.status == 1 ? (
            <div className="status">
              <div className="approved">
                <span>Approved</span>
              </div>
            </div>
          ) : item?.status === 2 ? (
            <div className="status">
              <div className="rejected">
                <span>Rejected</span>
              </div>
            </div>
          ) : null}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, merchant) => (
        <div className="actionBtn">
          <span onClick={() => handleViewClick(merchant)}>
            <TableEyeIcon />
          </span>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="UserTable">
        <Table
          dataSource={merchantListOfWebsite}
          columns={columns}
          loading={loading}
          style={{ width: "100%" }}
          scroll={{ x: 400 }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: false,
          }}
          onChange={handleTableChange}
        />
      </div>
      <Modal
        open={isModalVisible}
        onOk={handleModalClose}
        centered
        onCancel={handleModalClose}
        footer={null}
        maskClosable={false}
        className="common-modal alertModal"
      >
        <div className="modal-content">
          <h3>{selectedStatus === 1 ? "Approve" : "Reject"}!</h3>
          <p>
            Are you sure you want to{" "}
            {selectedStatus === 1 ? "approve" : "reject"} this merchant?
          </p>
          <div className="alertModal_footer">
            <ButtonCustom
              greenBtn
              large
              label="Yes"
              onClick={() => handleStatusUpdate()}
              disabled={disableBtn}
            />
            <ButtonCustom
              borderBtn
              large
              label="No"
              onClick={handleModalClose}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default MerchantDetailsTable;
